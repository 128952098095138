import React, { useState } from 'react';
import { Checkbox, Pagination, Row, Space, Typography } from 'antd';
import { useParams } from 'react-router';
import { SearchOutlined } from '@ant-design/icons';

import { CompanyContainer } from '../Company/styled';
import { IParamsGetAllCompany } from '../../../types/api/public';
import useGetAllStatementsPublic from '../../../api/public/useGetAllStatementsPublic';
import PublicCard from '../../../components/PublicListCard';
import { AsnSearch, Spinner } from '../../../components';
import { COLORS } from '../../../constants';
import { CheckboxValueType } from '../../../types/global';

import { ListContainer } from './styled';

const { AZURE } = COLORS;
const { Title } = Typography;

const CompanyActiveStatements: React.FC = () => {
  const { id } = useParams();
  const [search, setSearch] = useState<string>('');
  const [companyType, setCompanyType] = useState<CheckboxValueType[]>([]);
  const [params, setParams] = useState<IParamsGetAllCompany>({
    offset: 0,
    search: '',
  });

  const { data, isFetching } = useGetAllStatementsPublic(
    {
      ...params,
      limit: 10,
      companyIds: id ? [id] : [],
      companyTypes: companyType,
    },
    { enabled: true },
  );

  const onSearch = (e: React.SyntheticEvent<HTMLInputElement>): void => {
    setParams({
      ...params,
      search: e.currentTarget.value,
      offset: 0,
    });
    setSearch(e.currentTarget.value);
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (e.target.value === '') {
      setParams({
        ...params,
        offset: 0,
        search: '',
      });
      setSearch('');
    }
    setSearch(e.target.value);
  };

  const onTypeChange = (value: CheckboxValueType[]): void => {
    setCompanyType(value);
    setParams({
      ...params,
      offset: 0,
    });
  };

  const handlePagination = (page: number): void => {
    setParams({
      ...params,
      offset: (page - 1) * 10,
    });
  };

  return (
    <CompanyContainer>
      <div
        style={{
          minWidth: '285px',
        }}
      >
        <Checkbox.Group
          onChange={onTypeChange}
          value={companyType}
          style={{
            marginTop: '6.5rem',
          }}
        >
          <Space direction='vertical'>
            <Checkbox value={'WORK'}>Գործատու ({data.companyStatementsCount?.workCount})</Checkbox>
            <Checkbox value={'TRINING'}>
              Ուսուցում և վերապատրաստում ({data.companyStatementsCount?.trainingCount}){' '}
            </Checkbox>
            <Checkbox value={'OTHER'}>Այլ ({data.companyStatementsCount?.otherCount})</Checkbox>
          </Space>
        </Checkbox.Group>
      </div>
      <ListContainer>
        <Title level={5} style={{ color: AZURE, marginBottom: '1rem' }}>
          {data.result?.[0]?.companyName}
        </Title>
        <AsnSearch
          suffix={<SearchOutlined />}
          style={{ maxWidth: 450 }}
          onPressEnter={onSearch}
          onChange={onChange}
          value={search}
        />
        {isFetching ? (
          <Spinner />
        ) : (
          <>{data.result?.map((statement) => <PublicCard key={statement.id} info={statement} />)}</>
        )}
        {data?.count <= 10 ? null : (
          <Row justify={'center'} style={{ padding: '32px 0px' }}>
            <Pagination
              total={data?.count}
              showSizeChanger={false}
              current={params?.offset / 10 + 1}
              onChange={handlePagination}
            />
          </Row>
        )}
      </ListContainer>
    </CompanyContainer>
  );
};

export default CompanyActiveStatements;
