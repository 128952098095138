import React, { useEffect, useState } from 'react';
import { Form, Image, Input, Upload, message } from 'antd';
import { useNavigate } from 'react-router-dom';

import { FormContent } from '../../../LecturersForm/styled';
import AsnButton from '../../../Button';
import { PATHS } from '../../../../constants';
import { uploadPropsImage } from '../../../../helpers';
import { ReactComponent as AddPhotoSvg } from '../../../../assets/icons/addPhoto.svg';
import useGetStoreById from '../../../../api/admin/stories/useGetStorieById';
import { IFormStories } from '../../../../types/admin/stories/types';
import useCreateStories from '../../../../api/admin/stories/useCreateStories';
import useUpdateStories from '../../../../api/admin/stories/useUdateStories';
import SuccessModal from '../../../SuccessModal';
import Spinner from '../../../Spinner';

import { ButtonsContainer } from './styled';

const { TextArea } = Input;

const StoriesForm: React.FC<{ edit?: string }> = ({ edit }) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [urlPhoto, setUrlPhoto] = useState<string | undefined>(undefined);
  const [isChanged, setIsChanged] = useState<boolean>(false);
  const [isSuccessCreated, setIsSuccessCreated] = useState(false);
  const [isSuccessUpdated, setIsSuccessUpdated] = useState(false);
  const [disabledButton, setDisabledButton] = useState(false);

  const {
    data: { result },
    isFetching,
  } = useGetStoreById(edit, {
    enabled: Boolean(edit),
  });
  const { mutate: createStoriesFn } = useCreateStories();
  const { mutate: updateStoriesById } = useUpdateStories({
    onSuccess: () => {
      setIsSuccessUpdated(true);
      setDisabledButton(false);
    },
    onError: (error: string) => {
      setDisabledButton(false);
      void message.error(error as unknown as string, 2);
    },
  });
  const onFinish = (values: IFormStories): void => {
    setDisabledButton(true);
    if (edit) {
      if (isChanged) {
        updateStoriesById({
          id: edit,
          storiesData: {
            ...values,
            link: values.link === '' ? undefined : values.link,
            photo: urlPhoto ? urlPhoto : undefined,
          },
        });
      } else {
        navigate(PATHS.STORIES);
      }
      setIsChanged(false);
    } else {
      createStoriesFn(
        { ...values, photo: urlPhoto ?? '', link: values.link === '' ? undefined : values.link },
        {
          onSuccess: () => {
            setDisabledButton(false);
            setIsSuccessCreated(true);
          },
          onError: (error) => {
            setDisabledButton(false);
            void message.error(error as unknown as string, 2);
          },
        },
      );
      setIsChanged(false);
    }
  };

  useEffect(() => {
    if (result !== undefined) {
      form.setFieldsValue({
        successTitle: result.successTitle,
        story: result.story,
        photo: result.photo,
        link: result.link && result.link,
        companyLink: result.companyLink && result?.companyLink,
        companyName: result.companyName && result?.companyName,
      });
    }
  }, [result]);

  const onCloseCreateModal = (): void => {
    setIsSuccessCreated(false);
    navigate(PATHS.STORIES);
    setDisabledButton(false);
  };
  const onCloseUpdateModal = (): void => {
    setIsSuccessUpdated(false);
    navigate(PATHS.STORIES);
    setDisabledButton(false);
  };

  if (isFetching) {
    return <Spinner />;
  }

  return (
    <div
      style={{
        padding: '1.25rem 1.25rem 3.75rem 2.5rem',
        maxWidth: '70%',
      }}
    >
      <Form
        name='form_items'
        onFinish={onFinish}
        form={form}
        autoComplete='off'
        layout='vertical'
        initialValues={{ websites: [''] }}
        onValuesChange={(changedValues): void => {
          if (changedValues !== undefined) {
            setIsChanged(true);
          }
        }}
      >
        <FormContent>
          <Form.Item
            name='successTitle'
            label='Վերնագիր'
            rules={[
              {
                required: true,
                message: 'Վերնագիրը պարտադիր է',
              },
              { min: 3, message: 'Վերնագիրը պետք է ունենա առնվազն 3 նիշ' },
              { max: 50, message: 'Վերնագիրը պետք է ունենա առավելագույնը 50 նիշ' },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name='story'
            label='Պատմություն'
            rules={[
              {
                required: true,
                message: 'Պատմություն դաշտը պարտադիր է',
              },
              { min: 3, message: 'Պատմություն դաշտը պետք է ունենա առնվազն 3 նիշ' },
              { max: 380, message: 'Պատմություն դաշտը պետք է ունենա առավելագույնը 380 նիշ' },
            ]}
          >
            <TextArea />
          </Form.Item>
          <Form.Item
            name='link'
            label='Պատմության հղումը'
            rules={[
              {
                pattern: new RegExp(/^(https?:\/\/)?([\w-]+\.)+[\w-]+(\/[\w-./?%&=]*)?$/i),
                message: 'Հղում դաշտը պետք է ունենա հետեվյալ ձևաչափը http://example.com կամ https://example.com',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name='companyName'
            label='Ընկերության անվանումը'
            rules={[{ max: 50, message: 'Ընկերության անվանումը պետք է ունենա առավելագույնը 50 նիշ' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name='companyLink'
            label='Ընկերության հղումը'
            rules={[
              {
                pattern: new RegExp(/^(https?:\/\/)?([\w-]+\.)+[\w-]+(\/[\w-./?%&=]*)?$/i),
                message:
                  'Ընկերության հղումը դաշտը պետք է ունենա հետեվյալ ձևաչափը http://example.com կամ https://example.com',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name='photo'
            label='Կցել լուսանկար'
            rules={[
              {
                required: true,
                message: 'Դասավանդողի լուսանկարը պարտադիր է',
              },
            ]}
          >
            <Upload {...uploadPropsImage(setUrlPhoto)}>
              {!urlPhoto && !result?.photo ? (
                <AddPhotoSvg />
              ) : (
                <Image
                  preview={false}
                  src={urlPhoto ?? result.photo}
                  width={160}
                  height={160}
                  style={{ borderRadius: 6 }}
                />
              )}
            </Upload>
          </Form.Item>
          <ButtonsContainer>
            <AsnButton onClick={(): void => navigate(PATHS.STORIES)}>Չեղարկել</AsnButton>
            <AsnButton disabled={disabledButton} type='primary' htmlType='submit'>
              Հաստատել
            </AsnButton>
          </ButtonsContainer>
        </FormContent>
      </Form>
      <SuccessModal
        openSuccessModal={isSuccessCreated}
        onCloseModal={onCloseCreateModal}
        content={'Ձեր պատմությունը հաջողությամբ պահպանվել է'}
      />
      <SuccessModal
        openSuccessModal={isSuccessUpdated}
        onCloseModal={onCloseUpdateModal}
        content={'Ձեր պատմությունը հաջողությամբ փոփոխվել է'}
      />
    </div>
  );
};

export default StoriesForm;
