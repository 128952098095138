import React from 'react';
import { Image, Typography } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';

import useGetStoreById from '../../../../api/admin/stories/useGetStorieById';
import { AsnButton, Spinner } from '../../../../components';
import { COLORS, PATHS } from '../../../../constants';

import { StoriesCardContainer, StoriesContainer, TextContainer } from './styled';

const { Text, Title, Link } = Typography;

const { DARK_GRAY } = COLORS;

const StoriesView: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const {
    data: { result },
    isFetching,
  } = useGetStoreById(id, {
    enabled: Boolean(id),
  });

  if (isFetching) {
    return <Spinner />;
  }

  return (
    <StoriesContainer>
      <StoriesCardContainer>
        <TextContainer>
          <Title level={5}>{result?.successTitle}</Title>
          <Text style={{ fontSize: '16px', fontWeight: '400', margin: 0 }}>{result?.story}</Text>
          {result?.link ? (
            <Link
              style={{
                color: DARK_GRAY,
                marginTop: '1rem',
              }}
              underline
              target='_blank'
              href={result?.link}
            >
              Տեսնել ավելին
            </Link>
          ) : null}
        </TextContainer>
        <div>
          <Image preview={false} src={result?.photo} width={387} height={258} style={{ borderRadius: 6 }} />
        </div>
      </StoriesCardContainer>
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <AsnButton type='primary' onClick={(): void => navigate(PATHS.STORIES)}>
          Փակել
        </AsnButton>
      </div>
    </StoriesContainer>
  );
};

export default StoriesView;
