import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Space, Typography } from 'antd';

import useGetUserById from '../../../api/admin/user/useGetUserById';
import { Spinner } from '../../../components';
import { COLORS, PATHS } from '../../../constants';

import { MyInfoContainer } from './styled';

const { Title, Text } = Typography;
const { AZURE } = COLORS;

const AdminMyInfo: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { data: company, isLoading } = useGetUserById(id, {
    enabled: Boolean(id),
  });

  if (isLoading) {
    return <Spinner />;
  }
  return (
    <div style={{ display: 'flex', justifyContent: 'center', width: '100%', height: '100%', paddingTop: '7rem' }}>
      <MyInfoContainer>
        <Space direction='horizontal'>
          <Title level={2}>Անձնական տվյալներ</Title>
          <Text>{company.permission === 'EDIT' ? 'Խմբագիր' : 'Դիտորդ'}</Text>
        </Space>
        <Space
          direction='horizontal'
          style={{
            gap: '5rem',
          }}
        >
          <Space direction='vertical'>
            <Text>Email</Text>
            <Text>Անուն</Text>
            <Text>Ազգանուն</Text>
            <Text>Կազմակերպություն</Text>
          </Space>
          <Space direction='vertical'>
            <Text>{company.email}</Text>
            <Text>{company.firstName}</Text>
            <Text>{company.lastName}</Text>
            <Text>{company.organization}</Text>
          </Space>
        </Space>
        <Title
          level={4}
          underline
          style={{
            color: AZURE,
            cursor: 'pointer',
          }}
          onClick={(): void => navigate(PATHS.CHANGE_PASSWORD)}
        >
          Փոխել գաղտնաբառը
        </Title>
      </MyInfoContainer>
    </div>
  );
};

export default AdminMyInfo;
