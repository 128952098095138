import React, { useState } from 'react';
import { Input, Spin, Table } from 'antd';
import { TableProps } from 'antd/lib';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';

import { PostsContainer } from '../../../../pages/admin/announcements/styled';
import { ReactComponent as SearchIcon } from '../../../../assets/icons/search.svg';
import useGetAnnouncements from '../../../../api/admin/announcements/useGetAnnouncements';
import { announcementColumns } from '../columns';
import { IPagination, ISortCompany } from '../../../../types/admin/users/company';
import useGetRejectionReasonById from '../../../../api/admin/announcements/useGetRejectionReasonById';
import { IAnnouncementItem } from '../../../../api/admin/types/announcemenst/types';
import { PATHS, STATUS, tableDateFormat } from '../../../../constants';

const AnnouncementsContent: React.FC<{ announcementsType?: string }> = ({ announcementsType }) => {
  const navigate = useNavigate();
  const [sort, setSort] = useState<ISortCompany>({
    column: '',
    direction: '',
  });
  const [status, setStatus] = useState<string[]>([]);
  const [searchCompany, setSearchCompany] = useState<string>('');
  const [rejectionReasonId, setRejectionReasonId] = useState<string>('');

  const [tablePagination, setTablePagination] = useState<IPagination>({
    current: 1,
    pageSize: 10,
  });
  const { data, isLoading } = useGetAnnouncements({
    limit: tablePagination.pageSize,
    offset: (tablePagination.current - 1) * 10,
    search: searchCompany === '' ? undefined : searchCompany,
    type: announcementsType !== undefined ? [announcementsType] : undefined,
    statuses: status.length === 0 ? undefined : [...status],
    orderBy: sort.column === '' && sort.direction === '' ? undefined : { ...sort },
  });

  const {
    data: { result },
    isLoading: isRejectionReasonLoading,
  } = useGetRejectionReasonById(rejectionReasonId, {
    enabled: !!rejectionReasonId,
  });

  const handleTableChange: TableProps<IAnnouncementItem>['onChange'] = (pagination, filters, sorter) => {
    if ((sorter as { order: string }).order !== undefined) {
      setSort({
        column: (sorter as { field: string }).field,
        direction: (sorter as { order: string }).order === 'descend' ? 'DESC' : 'ASC',
      });
    } else {
      setSort({
        column: '',
        direction: '',
      });
    }
    if (filters.status !== null) {
      setStatus(filters.status as string[]);
    } else {
      setStatus([]);
    }

    setTablePagination({
      current: pagination.current,
      total: pagination.total,
      pageSize: 10,
    });
  };

  const onSearchAnnouncementsEnter = (e: React.SyntheticEvent<HTMLInputElement>): void => {
    setSearchCompany((e.target as HTMLInputElement).value.trim());
    setTablePagination({
      ...tablePagination,
      current: 1,
    });
  };

  const onExpendable = (expanded: boolean, record: IAnnouncementItem): void => {
    if (expanded) {
      setRejectionReasonId(record?.id);
    } else {
      setRejectionReasonId('');
    }
  };

  return (
    <PostsContainer>
      <Input
        placeholder='Օրինակ'
        prefix={<SearchIcon />}
        style={{ width: '40%' }}
        onPressEnter={onSearchAnnouncementsEnter}
      />
      <Table
        columns={announcementColumns}
        dataSource={data?.result}
        rowKey={(record): string => record?.id}
        loading={isLoading}
        pagination={{
          ...tablePagination,
          position: ['bottomCenter'],
          total: data?.count,
        }}
        onChange={handleTableChange}
        expandable={{
          expandedRowRender: (): React.ReactNode => {
            return (
              <>
                {isRejectionReasonLoading ? (
                  <Spin />
                ) : (
                  <p style={{ margin: 0, display: 'flex', flexDirection: 'row', gap: '16px' }}>
                    <p>{`Ադմին\` ${result.rejectedAdminName}`}</p>
                    <p>{`Ամսաթիվը\` ${dayjs(result.rejectionDate).format(tableDateFormat)}`}</p>
                    <p>{`Պատճառ\` ${result.rejectionText}`}</p>
                  </p>
                )}
              </>
            );
          },
          rowExpandable: (record): boolean => {
            return record.status === STATUS.BLOCKED;
          },
          expandedRowKeys: [rejectionReasonId],
        }}
        onExpand={onExpendable}
        onRow={(
          record,
        ): {
          onClick: (event: React.SyntheticEvent) => void;
        } => {
          return {
            onClick: (event: React.SyntheticEvent<Element, Event>): void => {
              const target = event.target as Element;
              if (!target.closest('svg')) {
                if (record.type === 'TRINING') {
                  navigate(PATHS.ANNOUNCEMENTS_COURSE_VIEW.replace(':id', record.id));
                } else if (record.type === 'WORK') {
                  navigate(PATHS.ANNOUNCEMENTS_WORK_VIEW.replace(':id', record.id));
                } else if (record.type === 'OTHER') {
                  navigate(PATHS.ANNOUNCEMENTS_OTHER_VIEW.replace(':id', record.id));
                }
              }
            },
          };
        }}
      />
    </PostsContainer>
  );
};
export default AnnouncementsContent;
