import React from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { Row, Button, Col, Typography, Input } from 'antd';

import { AsnForm } from '../../../components';
import { passwordRules, validate_messages, PATHS } from '../../../constants';
import { ResetPasswordVariables } from '../../../types/api/auth';
import { ReactComponent as Logo } from '../../../assets/icons/logo.svg';
import { rulesPassword } from '../../../helpers';
import useAcceptInvitation from '../../../api/auth/useAcceptInvitation';

const { Title } = Typography;

const AcceptInvitation: React.FC = () => {
  const navigate = useNavigate();
  const { mutate: acceptInvitation } = useAcceptInvitation();

  const [form] = AsnForm.useForm();

  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  const onFinish = (values: ResetPasswordVariables): void => {
    acceptInvitation({
      ...values,
      token,
    });
  };

  return (
    <>
      <Row style={{ padding: '22px 80px' }}>
        <Logo onClick={(): void => navigate(PATHS.LANDING)} style={{ cursor: 'pointer' }} />
      </Row>
      <Row
        style={{
          backgroundImage: 'url(./assets/auth_left.png)',
          backgroundSize: 'contain',
          height: 'calc(100vh - 100px)',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <Col span={20} push={4}>
          <Row align={'middle'} justify={'center'} style={{ height: 'calc(90vh - 100px)' }}>
            <AsnForm
              form={form}
              layout='vertical'
              onFinish={(values): void => onFinish(values as ResetPasswordVariables)}
              style={{ maxWidth: '460px', width: '42vw' }}
              validateMessages={validate_messages}
            >
              <Title level={2} style={{ marginBottom: 30 }}>
                Ստեղծել նոր գաղտնաբառ
              </Title>
              <AsnForm.Item
                name='password'
                label='Նոր գաղտնաբառ'
                rules={[...passwordRules]}
                normalize={(value: string): string => value.trim()}
              >
                <Input.Password />
              </AsnForm.Item>
              <AsnForm.Item
                name='repeatPassword'
                label='Կրկնել գաղտնաբառը'
                style={{ marginBottom: 40 }}
                rules={rulesPassword('password')}
                dependencies={['password']}
                normalize={(value: string): string => value.trim()}
              >
                <Input.Password />
              </AsnForm.Item>
              <Row justify={'end'}>
                <Button type='primary' htmlType='submit'>
                  Առաջ
                </Button>
              </Row>
            </AsnForm>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default AcceptInvitation;
