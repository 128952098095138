import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import { Tooltip } from 'antd';

import { COLORS, PERMISSION, STATUS, tableDateFormat } from '../../../../constants';
import { ReactComponent as BlockIcon } from '../../../../assets/icons/block.svg';
import { ReactComponent as UnBlockIcon } from '../../../../assets/icons/un-block.svg';
import { ReactComponent as MessageIcon } from '../../../../assets/icons/message.svg';
import { ReactComponent as EditedIcon } from '../../../../assets/icons/edit.svg';
import { ReactComponent as DeleteIcon } from '../../../../assets/icons/delete.svg';
import { IListPendingAdmin } from '../../../../api/admin/types/users/admin';
import { IBlockAdmin, IDeleteAdmin } from '../../../../types/admin/users/company';

import { TableActionContainer } from './styled';

const { WHITE, BLACK } = COLORS;
export const adminColumns = (
  setOpenIsResendAdmin: React.Dispatch<React.SetStateAction<string>>,
  setOpenIsBlockedAdmin: React.Dispatch<React.SetStateAction<IBlockAdmin>>,
  setOpenIsDeletedAdmin: React.Dispatch<React.SetStateAction<IDeleteAdmin>>,
  setOpenEditedAdminId: React.Dispatch<React.SetStateAction<string>>,
): ColumnsType<IListPendingAdmin> => {
  return [
    {
      title: 'Անուն Ազգանուն',
      dataIndex: 'fullName',
    },
    {
      title: 'Դեր',
      dataIndex: 'permission',
      render: (role: string) => <div>{role === PERMISSION.EDIT ? 'Խմբագիր' : 'Դիտորդ'}</div>,
    },
    {
      title: 'Ստեղծման ամսաթիվ',
      dataIndex: 'createdAt',
      sorter: true,
      render: (createdAt: string): React.ReactNode => <div>{dayjs(createdAt).format(tableDateFormat)}</div>,
    },
    {
      title: 'Վերջին թարմացում',
      dataIndex: 'updatedAt',
      sorter: true,
      render: (updatedAt: string): React.ReactNode => <div>{dayjs(updatedAt).format(tableDateFormat)}</div>,
    },
    {
      title: 'Ստատուս',
      dataIndex: 'status',
      filters: [
        {
          text: 'Ակտիվ',
          value: STATUS.ACTIVE,
        },
        {
          text: 'Չակտիվացված',
          value: STATUS.INACTIVE,
        },
        {
          text: 'Արգելափակված',
          value: STATUS.BLOCKED,
        },
      ],
      render: (row: string): React.ReactNode => (
        <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
          <div
            style={
              row === STATUS.ACTIVE
                ? { borderRadius: '50%', backgroundColor: '#52C41A', width: '5px', height: '5px' }
                : row === STATUS.INACTIVE
                  ? { borderRadius: '50%', backgroundColor: '#CDCDCD', width: '5px', height: '5px' }
                  : { borderRadius: '50%', backgroundColor: '#FF4D4F', width: '5px', height: '5px' }
            }
          />
          <div>{row === STATUS.ACTIVE ? 'Ակտիվ' : row === STATUS.INACTIVE ? 'Չակտիվացված' : 'Արգելափակված'}</div>
        </div>
      ),
    },
    {
      title: 'Գործողություն',
      key: 'operation',
      render: (row: IListPendingAdmin) => (
        <TableActionContainer>
          {row.status === STATUS.ACTIVE ? (
            <>
              <Tooltip
                placement='topLeft'
                title={'Արգելափակել'}
                arrow={false}
                overlayInnerStyle={{
                  backgroundColor: WHITE,
                  color: BLACK,
                }}
              >
                <button
                  onClick={(): void =>
                    setOpenIsBlockedAdmin({
                      id: row?.id,
                      status: row.status,
                      fullName: row.fullName,
                    })
                  }
                >
                  <BlockIcon />
                </button>
              </Tooltip>
              <Tooltip
                placement='topLeft'
                title={'Խմբագրել'}
                arrow={false}
                overlayInnerStyle={{
                  backgroundColor: WHITE,
                  color: BLACK,
                }}
              >
                <button onClick={(): void => setOpenEditedAdminId(row?.id)}>
                  <EditedIcon />
                </button>
              </Tooltip>
            </>
          ) : null}
          {row.status === STATUS.INACTIVE ? (
            <>
              <Tooltip
                placement='topLeft'
                title={'Կրկին հրավիրել'}
                arrow={false}
                overlayInnerStyle={{
                  backgroundColor: WHITE,
                  color: BLACK,
                }}
              >
                <button onClick={(): void => setOpenIsResendAdmin(row?.email)}>
                  <MessageIcon />
                </button>
              </Tooltip>
              <Tooltip
                placement='topLeft'
                title={'Խմբագրել'}
                arrow={false}
                overlayInnerStyle={{
                  backgroundColor: WHITE,
                  color: BLACK,
                }}
              >
                <button onClick={(): void => setOpenEditedAdminId(row?.id)}>
                  <EditedIcon />
                </button>
              </Tooltip>
              <Tooltip
                placement='topLeft'
                title={'Ջնջել'}
                arrow={false}
                overlayInnerStyle={{
                  backgroundColor: WHITE,
                  color: BLACK,
                }}
              >
                <button onClick={(): void => setOpenIsDeletedAdmin({ id: row?.id, fullName: row.fullName })}>
                  <DeleteIcon />
                </button>
              </Tooltip>
            </>
          ) : null}
          {row.status === STATUS.BLOCKED ? (
            <>
              <Tooltip
                placement='topLeft'
                title={'Ապաարգելափակել'}
                arrow={false}
                overlayInnerStyle={{
                  backgroundColor: WHITE,
                  color: BLACK,
                }}
              >
                <button
                  onClick={(): void =>
                    setOpenIsBlockedAdmin({
                      id: row?.id,
                      status: row.status,
                      fullName: row.fullName,
                    })
                  }
                >
                  <UnBlockIcon />
                </button>
              </Tooltip>
            </>
          ) : null}
        </TableActionContainer>
      ),
    },
  ];
};
