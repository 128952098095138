import React, { useState } from 'react';
import { Form, Input, Modal, Table } from 'antd';
import { TableProps } from 'antd/lib';
import { useQueryClient } from '@tanstack/react-query';

import AsnButton from '../../../Button';
import useGetAdminsList from '../../../../api/admin/users/useGetAdmins';
import { IListPendingAdmin } from '../../../../api/admin/types/users/admin';
import { IBlockAdmin, IDeleteAdmin, IFormData, IPagination, ISortCompany } from '../../../../types/admin/users/company';
import { ReactComponent as SearchIcon } from '../../../../assets/icons/search.svg';
import useResendAdmin from '../../../../api/admin/users/useResendAdmin';
import ActionModal from '../../../ActionModal';
import useBlockUser from '../../../../api/admin/users/useBlockAdmin';
import { STATUS } from '../../../../constants';
import AddAdminModalContent from '../AddAdminContent';
import useDeleteAdmin from '../../../../api/admin/users/useDeleteAdmin';
import useCreateAdmin from '../../../../api/admin/users/useCreateAdmin';
import useUpdateAdmin from '../../../../api/admin/users/useEditAdmin';
import SuccessModal from '../../../SuccessModal';
import { useAuth } from '../../../../hooks/useAuth';

import { adminColumns } from './columns';
import { ButtonContainer } from './styled';

const AdminTable: React.FC = () => {
  const [modalForm] = Form.useForm();
  const queryClient = useQueryClient();
  const { user } = useAuth();
  const [statusCompany, setStatusCompany] = useState<string[]>([STATUS.ACTIVE, STATUS.INACTIVE, STATUS.BLOCKED]);
  const [openCreateAdmin, setOpenCreateAdmin] = useState<boolean>(false);
  const [isChanged, setIsChanged] = useState<boolean>(false);
  const [openEditedAdminId, setOpenEditedAdminId] = useState<string>('');
  const [isSuccessUpdated, setIsSuccessUpdated] = useState(false);
  const [searchCompany, setSearchCompany] = useState<string>('');
  const [openIsResendAdmin, setOpenIsResendAdmin] = useState<string>('');
  const [sortCompany, setSortCompany] = useState<ISortCompany>({
    column: '',
    direction: '',
  });
  const [tablePagination, setTablePagination] = useState<IPagination>({
    current: 1,
    pageSize: 10,
  });
  const [openIsBlockedAdmin, setOpenIsBlockedAdmin] = useState<IBlockAdmin>({
    id: '',
    status: '',
    fullName: '',
  });
  const [openIsDeletedAdmin, setOpenIsDeletedAdmin] = useState<IDeleteAdmin>({
    id: '',
    fullName: '',
  });

  const { data, isLoading } = useGetAdminsList({
    limit: tablePagination.pageSize,
    offset: (tablePagination.current - 1) * 10,
    statuses: statusCompany,
    search: searchCompany === '' ? undefined : searchCompany,
    orderBy: sortCompany.column === '' && sortCompany.direction === '' ? undefined : { ...sortCompany },
  });

  const { mutate: resendAdminByEmail } = useResendAdmin();

  const { mutate: blockAdminById } = useBlockUser({
    onSuccess: () => {
      setOpenIsBlockedAdmin({
        id: '',
        status: '',
        fullName: '',
      });
      void queryClient.invalidateQueries(['api/user']);
    },
  });

  const { mutate: deleteAdminById } = useDeleteAdmin({
    onSuccess: () => {
      setOpenIsDeletedAdmin({
        id: '',
        fullName: '',
      });
      void queryClient.invalidateQueries(['api/user']);
    },
  });
  const { mutate: createAdminFn } = useCreateAdmin();

  const { mutate: updateAdminById } = useUpdateAdmin({
    onSuccess: () => {
      void queryClient.invalidateQueries(['api/user']);
      void queryClient.invalidateQueries(['api/user/:id', openEditedAdminId]);
      onCloseEditAdminModal();
      modalForm.resetFields();
      setIsSuccessUpdated(true);
    },
  });

  const onFinish = (values: IFormData): void => {
    if (!openEditedAdminId) {
      createAdminFn(values, {
        onSuccess: () => {
          onCloseCreateAdminModal();
          void queryClient.invalidateQueries(['api/user']);
          modalForm.resetFields();
        },
      });
    } else {
      if (isChanged) {
        updateAdminById({
          id: openEditedAdminId,
          userData: {
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            organization: values.organization,
            permission: values.permission,
          },
        });
      }
      setIsChanged(false);
    }
  };

  const onSearchCompanyEnter = (e: React.SyntheticEvent<HTMLInputElement>): void => {
    setSearchCompany((e.target as HTMLInputElement).value.trim());
    setTablePagination({
      ...tablePagination,
      current: 1,
    });
  };

  const handleTableChange: TableProps<IListPendingAdmin>['onChange'] = (pagination, filters, sorter) => {
    if ((sorter as { order: string }).order !== undefined) {
      setSortCompany({
        column: (sorter as { field: string }).field,
        direction: (sorter as { order: string }).order === 'descend' ? 'DESC' : 'ASC',
      });
    } else {
      setSortCompany({
        column: '',
        direction: '',
      });
    }
    if (filters.status !== null) {
      setStatusCompany(filters.status as string[]);
    } else {
      setStatusCompany([STATUS.ACTIVE, STATUS.INACTIVE, STATUS.BLOCKED]);
    }
    setTablePagination({
      current: pagination.current,
      total: pagination.total,
      pageSize: 10,
    });
  };

  const onCloseModal = (): void => {
    setOpenIsResendAdmin('');
  };

  const onResendAdmin = (): void => {
    resendAdminByEmail(
      { email: openIsResendAdmin },
      {
        onSuccess: () => {
          setOpenIsResendAdmin('');
        },
      },
    );
  };

  const onCloseBlockAddAdminModal = (): void => {
    setOpenIsBlockedAdmin({
      status: '',
      fullName: '',
      id: '',
    });
  };

  const onDeleteAdmin = (): void => {
    deleteAdminById(openIsDeletedAdmin.id);
  };
  const onCloseDeleteModal = (): void => {
    setOpenIsDeletedAdmin({
      id: '',
      fullName: '',
    });
  };

  const onBlockAdmin = (): void => {
    blockAdminById({ id: openIsBlockedAdmin.id });
  };

  const onCloseCreateAdminModal = (): void => {
    setOpenCreateAdmin(false);
    modalForm.resetFields();
  };

  const onCloseEditAdminModal = (): void => {
    setOpenEditedAdminId('');
    modalForm.resetFields();
  };

  const permissionColumns =
    user?.permission === 'VIEW' && user.role === 'ADMIN'
      ? adminColumns(setOpenIsResendAdmin, setOpenIsBlockedAdmin, setOpenIsDeletedAdmin, setOpenEditedAdminId).filter(
          (item) => item.key !== 'operation',
        )
      : adminColumns(setOpenIsResendAdmin, setOpenIsBlockedAdmin, setOpenIsDeletedAdmin, setOpenEditedAdminId);

  return (
    <div>
      <Input
        placeholder='Օրինակ'
        prefix={<SearchIcon />}
        style={{ width: '40%' }}
        onPressEnter={onSearchCompanyEnter}
      />
      <ButtonContainer>
        {user?.permission === 'EDIT' && user.role === 'SUPER_ADMIN' && (
          <AsnButton onClick={(): void => setOpenCreateAdmin(true)}>+ Ավելացնել ադմինիստրատոր</AsnButton>
        )}
      </ButtonContainer>
      <Table
        columns={permissionColumns}
        dataSource={data?.result}
        rowKey={(record): string => record?.id}
        loading={isLoading}
        pagination={{
          ...tablePagination,
          position: ['bottomCenter'],
          total: data?.count,
        }}
        onChange={handleTableChange}
      />
      <Modal centered open={openIsResendAdmin !== ''} onCancel={onCloseModal} footer={false}>
        <ActionModal
          text={`Համոզվա՞ծ եք, որ ցանկանում եք կրկին ուղարկել Հարթակին միանալու հրավերը "${openIsResendAdmin}" էլեկտրոնային հասցեին`}
          resolveFn={onResendAdmin}
          rejectFn={onCloseModal}
        />
      </Modal>
      <Modal centered open={openIsDeletedAdmin.id !== ''} onCancel={onCloseDeleteModal} footer={false}>
        <ActionModal
          text={`Համոզվա՞ծ եք, որ ցանկանում եք հեռացնել "${openIsDeletedAdmin.fullName}"`}
          resolveFn={onDeleteAdmin}
          rejectFn={onCloseDeleteModal}
        />
      </Modal>
      <Modal centered open={openIsResendAdmin !== ''} onCancel={onCloseModal} footer={false}>
        <ActionModal
          text={`Համոզվա՞ծ եք, որ ցանկանում եք կրկին ուղարկել Հարթակին միանալու հրավերը "${openIsResendAdmin}" էլեկտրոնային հասցեին`}
          resolveFn={onResendAdmin}
          rejectFn={onCloseModal}
        />
      </Modal>
      <Modal centered open={!!openIsBlockedAdmin.id} onCancel={onCloseBlockAddAdminModal} footer={false}>
        <ActionModal
          text={
            openIsBlockedAdmin.status === STATUS.BLOCKED
              ? `Համոզվա՞ծ եք, որ ցանկանում եք Ապաարգելափակել ${openIsBlockedAdmin.fullName}`
              : `Համոզվա՞ծ եք, որ ցանկանում եք Արգելափակել ${openIsBlockedAdmin.fullName} օգտվողին`
          }
          resolveFn={onBlockAdmin}
          rejectFn={onCloseBlockAddAdminModal}
        />
      </Modal>
      <Modal centered open={openCreateAdmin} onCancel={onCloseCreateAdminModal} footer={false} width={'40vw'}>
        <Form
          name='form_items'
          onFinish={onFinish}
          form={modalForm}
          autoComplete='off'
          layout='vertical'
          onValuesChange={(changedValues): void => {
            if (changedValues !== undefined) {
              setIsChanged(true);
            }
          }}
        >
          <AddAdminModalContent />
        </Form>
      </Modal>
      <Modal centered open={!!openEditedAdminId} onCancel={onCloseEditAdminModal} footer={false} width={'40vw'}>
        <Form
          name='form_items'
          onFinish={onFinish}
          form={modalForm}
          autoComplete='off'
          layout='vertical'
          onValuesChange={(changedValues): void => {
            if (changedValues !== undefined) {
              setIsChanged(true);
            }
          }}
        >
          <AddAdminModalContent openEditedAdminId={openEditedAdminId} />
        </Form>
      </Modal>
      <SuccessModal
        openSuccessModal={isSuccessUpdated}
        onCloseModal={(): void => setIsSuccessUpdated(false)}
        content={'Տվյալները  հաջողությամբ թարմացվել են'}
      />
    </div>
  );
};

export default AdminTable;
