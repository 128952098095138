import React, { useState } from 'react';
import { Input, Modal, Table, TableProps } from 'antd';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import { AsnButton, SuccessModal } from '../../../components';
import { IPaginationStories, ISortStories } from '../../../types/admin/stories/types';
import { IStoriesData } from '../../../api/admin/types/stories/types';
import useGetAllStories from '../../../api/admin/stories/useGetAllStories';
import ActionModal from '../../../components/ActionModal';
import useDeleteStories from '../../../api/admin/stories/useDeleteStories';
import useUpdateStatusStories from '../../../api/admin/stories/useUpdateStatusStories';
import { ReactComponent as SearchIcon } from '../../../assets/icons/search.svg';
import { PATHS } from '../../../constants';
import { useAuth } from '../../../hooks/useAuth';

import { columnsStories } from './columns';
import { TablesContainer, TopContainerStories } from './styled';

const SuccessStories: React.FC = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const queryClient = useQueryClient();
  const [isDeletedStoryId, setIsDeletedStoryId] = useState('');
  const [isSuccessDeleted, setIsSuccessDeleted] = useState(false);
  const [isActivatedStoryId, setIsActivatedStoryId] = useState({
    id: '',
    status: '',
  });
  const [isSuccessActivated, setIsSuccessActivated] = useState(false);
  const [searchCompany, setSearchCompany] = useState<string>('');
  const [status, setStatus] = useState<string[]>([]);
  const [sortCompany, setSortCompany] = useState<ISortStories>({
    column: '',
    direction: '',
  });
  const [tablePagination, setTablePagination] = useState<IPaginationStories>({
    current: 1,
    pageSize: 10,
  });

  const { data, isLoading } = useGetAllStories({
    limit: tablePagination.pageSize,
    offset: (tablePagination.current - 1) * 10,
    search: searchCompany === '' ? undefined : searchCompany,
    statuses: status.length === 0 ? undefined : [...status],
    orderBy: sortCompany.column === '' && sortCompany.direction === '' ? undefined : { ...sortCompany },
  });
  const { mutate: deleteStoriesById } = useDeleteStories({
    onSuccess: () => {
      setIsDeletedStoryId('');
      void queryClient.invalidateQueries(['api/admin/success-stories/all']);
      setIsSuccessDeleted(true);
    },
  });

  const { mutate: updateStatusStoriesById } = useUpdateStatusStories({
    onSuccess: () => {
      setIsActivatedStoryId({
        ...isActivatedStoryId,
        id: '',
      });
      void queryClient.invalidateQueries(['api/admin/success-stories/all']);
      setIsSuccessActivated(true);
    },
  });

  const onSearchCompanyEnter = (e: React.SyntheticEvent<HTMLInputElement>): void => {
    setSearchCompany((e.target as HTMLInputElement).value.trim());
    setTablePagination({
      ...tablePagination,
      current: 1,
    });
  };

  const handleTableChange: TableProps<IStoriesData>['onChange'] = (pagination, filters, sorter) => {
    if ((sorter as { order: string }).order !== undefined) {
      setSortCompany({
        column: (sorter as { field: string }).field,
        direction: (sorter as { order: string }).order === 'descend' ? 'DESC' : 'ASC',
      });
    } else {
      setSortCompany({
        column: '',
        direction: '',
      });
    }
    if (filters.status !== null) {
      setStatus(filters.status as string[]);
    } else {
      setStatus([]);
    }
    setTablePagination({
      current: pagination.current,
      total: pagination.total,
      pageSize: 10,
    });
  };

  const onCloseDeleteStoryModal = (): void => {
    setIsDeletedStoryId('');
  };
  const onApproveDeleteStoryModal = (): void => {
    deleteStoriesById(isDeletedStoryId);
  };

  const onCloseActivatedStoryModal = (): void => {
    setIsActivatedStoryId({
      id: '',
      status: '',
    });
  };
  const onApproveActivatedStoryModal = (): void => {
    updateStatusStoriesById(isActivatedStoryId.id);
  };

  const permissionColumns =
    user?.permission === 'VIEW' && user.role === 'ADMIN'
      ? columnsStories(setIsDeletedStoryId, setIsActivatedStoryId).filter((item) => item.key !== 'operation')
      : columnsStories(setIsDeletedStoryId, setIsActivatedStoryId);

  return (
    <TablesContainer>
      <Input
        placeholder='Օրինակ'
        prefix={<SearchIcon />}
        style={{ width: '40%' }}
        onPressEnter={onSearchCompanyEnter}
      />
      <TopContainerStories>
        {!(user?.permission === 'VIEW' && user.role === 'ADMIN') && (
          <AsnButton onClick={(): void => navigate(PATHS.STORIES_CREATE)}>Ստեղծել նոր</AsnButton>
        )}
      </TopContainerStories>
      <Table
        columns={permissionColumns}
        dataSource={data?.result}
        rowKey={(record): string => record?.id}
        loading={isLoading}
        scroll={{ x: 'auto' }}
        pagination={{
          ...tablePagination,
          position: ['bottomCenter'],
          total: data?.count,
        }}
        onChange={handleTableChange}
        onRow={(
          record,
        ): {
          onClick: (event: React.SyntheticEvent) => void;
        } => {
          return {
            onClick: (event: React.SyntheticEvent<Element, Event>): void => {
              const target = event.target as Element;
              if (!target.closest('svg')) navigate(PATHS.STORIES_VIEW.replace(':id', record.id));
            },
          };
        }}
      />
      <Modal centered open={isDeletedStoryId !== ''} onCancel={onCloseDeleteStoryModal} footer={false}>
        <ActionModal
          text={'Համոզվա՞ծ եք, որ ցանկանում եք հեռացնել պատմությունը'}
          resolveFn={onApproveDeleteStoryModal}
          rejectFn={onCloseDeleteStoryModal}
        />
      </Modal>
      <Modal centered open={isActivatedStoryId.id !== ''} onCancel={onCloseActivatedStoryModal} footer={false}>
        <ActionModal
          text={
            isActivatedStoryId?.status === 'activate'
              ? 'Համոզվա՞ծ եք, որ ցանկանում եք հեռացնել հրապարակված պատմությունը'
              : 'Համոզվա՞ծ եք, որ ցանկանում եք հրապարակել պատմությունը'
          }
          resolveFn={onApproveActivatedStoryModal}
          rejectFn={onCloseActivatedStoryModal}
        />
      </Modal>
      <SuccessModal
        openSuccessModal={isSuccessDeleted}
        onCloseModal={(): void => setIsSuccessDeleted(false)}
        content={'Ձեր պատմությունը հաջողությամբ հեռացվել է'}
      />
      <SuccessModal
        openSuccessModal={isSuccessActivated}
        onCloseModal={(): void => setIsSuccessActivated(false)}
        content={
          isActivatedStoryId?.status === 'activate'
            ? 'Ձեր պատմությունը հաջողությամբ հրապարակումից հեռացվել է'
            : 'Ձեր պատմությունը հաջողությամբ հրապարակվել է'
        }
      />
    </TablesContainer>
  );
};

export default SuccessStories;
