import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import useGetLecturersById from '../../../../api/company/Lecturers/useGetLecturersById';
import LecturersCard from '../../../../components/LecturersCard';
import { AsnButton, Spinner } from '../../../../components';
import { PATHS } from '../../../../constants';

import { ButtonContainer, ViewLecturersContainer } from './styled';

const ViewLecturers: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const {
    data: { result },
    isFetching,
  } = useGetLecturersById(id, {
    enabled: Boolean(id),
  });

  if (isFetching) {
    return <Spinner />;
  }

  return (
    <ViewLecturersContainer>
      <LecturersCard lecturersData={result} />
      <ButtonContainer>
        <AsnButton type='primary' onClick={(): void => navigate(PATHS.COMPANY_LECTURERS)}>
          Փակել
        </AsnButton>
      </ButtonContainer>
    </ViewLecturersContainer>
  );
};

export default ViewLecturers;
