import dayjs from 'dayjs';
import { ColumnsType } from 'antd/es/table';
import { Tooltip } from 'antd';

import { COLORS, STATUS, tableDateFormat } from '../../../../constants';
import { ReactComponent as BlockIcon } from '../../../../assets/icons/block.svg';
import { ReactComponent as UnBlockIcon } from '../../../../assets/icons/un-block.svg';
import { ReactComponent as MessageIcon } from '../../../../assets/icons/message.svg';
import { ICompanyListPending } from '../../../../api/admin/types/users/company';

const { WHITE, BLACK } = COLORS;

export const columns = (
  onChangeCompanyStatus: (row: ICompanyListPending) => void,
  setOpenIsResendCompany: React.Dispatch<React.SetStateAction<string>>,
): ColumnsType<ICompanyListPending> => {
  return [
    {
      title: 'Անվանում',
      dataIndex: 'name',
    },
    {
      title: 'Կարգավիճակ',
      dataIndex: 'status',
      filters: [
        {
          text: 'Ակտիվ',
          value: STATUS.ACTIVE,
        },
        {
          text: 'Չակտիվացված',
          value: STATUS.INACTIVE,
        },
        {
          text: 'Արգելափակված',
          value: STATUS.BLOCKED,
        },
      ],
      render: (row: string): React.ReactNode => (
        <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
          <div
            style={
              row === STATUS.ACTIVE
                ? { borderRadius: '50%', backgroundColor: '#52C41A', width: '5px', height: '5px' }
                : row === STATUS.INACTIVE
                  ? { borderRadius: '50%', backgroundColor: '#CDCDCD', width: '5px', height: '5px' }
                  : { borderRadius: '50%', backgroundColor: '#FF4D4F', width: '5px', height: '5px' }
            }
          />
          <div>{row === STATUS.ACTIVE ? 'Ակտիվ' : row === STATUS.INACTIVE ? 'Չակտիվացված' : 'Արգելափակված'}</div>
        </div>
      ),
    },
    {
      title: 'Ստեղծման ամսաթիվ',
      dataIndex: 'createdAt',
      sorter: true,
      render: (createdAt: string): React.ReactNode => <div>{dayjs(createdAt).format(tableDateFormat)}</div>,
    },
    {
      title: 'Վերջին թարմացում',
      dataIndex: 'updatedAt',
      sorter: true,
      render: (updatedAt: string): React.ReactNode => <div>{dayjs(updatedAt).format(tableDateFormat)}</div>,
    },
    {
      title: 'Գործողություն',
      key: 'operation',
      render: (row: ICompanyListPending) => (
        <div>
          {row.checkStatus === STATUS.ACTIVE ? (
            <Tooltip
              placement='topLeft'
              title={'Արգելափակել'}
              arrow={false}
              overlayInnerStyle={{
                backgroundColor: WHITE,
                color: BLACK,
              }}
            >
              <button
                style={{ background: 'none', border: 'none', cursor: 'pointer' }}
                onClick={(): void => onChangeCompanyStatus(row)}
              >
                <BlockIcon />
              </button>
            </Tooltip>
          ) : null}
          {row.checkStatus === STATUS.BLOCKED ? (
            <Tooltip
              placement='topLeft'
              title={'Ապաարգելափակել'}
              arrow={false}
              overlayInnerStyle={{
                backgroundColor: WHITE,
                color: BLACK,
              }}
            >
              <button
                style={{ background: 'none', border: 'none', cursor: 'pointer' }}
                onClick={(): void => onChangeCompanyStatus(row)}
              >
                <UnBlockIcon />
              </button>
            </Tooltip>
          ) : null}
          {row.checkStatus === STATUS.INACTIVE ? (
            <Tooltip
              placement='topLeft'
              title={'Կրկին հրավիրել'}
              arrow={false}
              overlayInnerStyle={{
                backgroundColor: WHITE,
                color: BLACK,
              }}
            >
              <button
                style={{ background: 'none', border: 'none', cursor: 'pointer', padding: 0 }}
                onClick={(): void => setOpenIsResendCompany(row?.email)}
              >
                <MessageIcon />
              </button>
            </Tooltip>
          ) : null}
        </div>
      ),
    },
  ];
};
