import React, { useState } from 'react';
import { Modal, Table } from 'antd';
import { TableProps } from 'antd/lib';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as SearchIcon } from '../../../assets/icons/search.svg';
import { AsnButton, AsnSearch, SuccessModal } from '../../../components';
import useGetLecturersList from '../../../api/company/Lecturers/useGetAllLecturers';
import { IPaginationLecturers } from '../../../types/company';
import { IDataLecturers } from '../../../api/admin/types/lecturers.tsx/types';
import ActionModal from '../../../components/ActionModal';
import useDeleteLecturers from '../../../api/company/Lecturers/useDeleteLecturers';
import { PATHS } from '../../../constants';

import { ButtonContainer, LecturersContainer } from './styled';
import { columnsLecturers } from './columns';

const Lecturers: React.FC = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [searchLecturers, setSearchLecturers] = useState<string>('');
  const [deleteLecturersId, setDeleteLecturersId] = useState<{ id: string; name: string }>({
    id: '',
    name: '',
  });
  const [isOpenDeleteLecturers, setIsOpenDeleteLecturers] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');

  const [tablePagination, setTablePagination] = useState<IPaginationLecturers>({
    current: 1,
    pageSize: 10,
  });

  const { data, isLoading } = useGetLecturersList({
    limit: tablePagination.pageSize,
    offset: (tablePagination.current - 1) * 10,
    search: searchLecturers === '' ? undefined : searchLecturers,
  });

  const { mutate: deleteLecturersById } = useDeleteLecturers({
    onSuccess: () => {
      setDeleteLecturersId({
        id: '',
        name: '',
      });
      void queryClient.invalidateQueries(['api/teacher/all']);
      setIsOpenDeleteLecturers(true);
    },
  });

  const handleTableChange: TableProps<IDataLecturers>['onChange'] = (pagination) => {
    setTablePagination({
      current: pagination.current,
      total: pagination.total,
      pageSize: 10,
    });
  };

  const onCloseDeleteModal = (): void => {
    setDeleteLecturersId({
      id: '',
      name: '',
    });
  };
  const onDeleteLecturers = (): void => {
    deleteLecturersById(deleteLecturersId.id);
  };

  const onPressSearch = (e: React.SyntheticEvent<HTMLInputElement>): void => {
    setSearchLecturers(e.currentTarget.value);
    setTablePagination({
      ...tablePagination,
      current: 1,
    });
    setSearch(e.currentTarget.value);
  };
  const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setSearch(e.currentTarget.value);
    if (e.target.value === '') {
      setSearchLecturers(e.currentTarget.value);
      setSearch('');
      setTablePagination({
        ...tablePagination,
        current: 1,
      });
    }
  };

  return (
    <LecturersContainer>
      <AsnSearch
        suffix={<SearchIcon />}
        style={{ width: '30vw', maxWidth: 450 }}
        onPressEnter={onPressSearch}
        onChange={onChange}
        value={search}
      />
      <ButtonContainer>
        <AsnButton onClick={(): void => navigate(PATHS.COMPANY_LECTURERS_CREATE)}>Ավելացնել դասավանդող</AsnButton>
      </ButtonContainer>
      <Table
        columns={columnsLecturers(setDeleteLecturersId)}
        dataSource={data?.result}
        rowKey={(record): string => record?.id}
        loading={isLoading}
        pagination={
          data?.count <= 10
            ? false
            : {
                ...tablePagination,
                position: ['bottomCenter'],
                total: data?.count,
              }
        }
        onChange={handleTableChange}
        onRow={(
          record,
        ): {
          onClick: (event: React.SyntheticEvent) => void;
        } => {
          return {
            onClick: (event: React.SyntheticEvent<Element, Event>): void => {
              const target = event.target as Element;
              if (!target.closest('svg')) navigate(PATHS.COMPANY_LECTURER_VIEW.replace(':id', record.id));
            },
          };
        }}
      />
      <Modal centered open={deleteLecturersId.id !== ''} onCancel={onCloseDeleteModal} footer={false}>
        <ActionModal
          text={`Համոզվա՞ծ եք, որ ցանկանում եք հեռացնել "${deleteLecturersId.name}" դասընթացավարին`}
          resolveFn={onDeleteLecturers}
          rejectFn={onCloseDeleteModal}
        />
      </Modal>
      <SuccessModal
        openSuccessModal={isOpenDeleteLecturers}
        onCloseModal={(): void => setIsOpenDeleteLecturers(false)}
        content={'Դասընթացավարը հաջողությամբ հեռացվել է'}
      />
    </LecturersContainer>
  );
};

export default Lecturers;
