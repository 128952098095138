import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Col, Image, Typography, Space, Badge, Button } from 'antd';
import styled from 'styled-components';

import { COLORS, PATHS } from '../../constants';
import { ReactComponent as LocationSvg } from '../../assets/icons/locationOrange.svg';
import { IMainCard } from '../../types/components';
import AsnButton from '../Button';
import FinishModal from '../FinishModal';
import { ReactComponent as EditSvg } from '../../assets/icons/editBlue.svg';

const { Paragraph, Text } = Typography;
const { WATER, TEXT_COLOR, CORAL, GRAY, AZURE, GREEN } = COLORS;

const AsnRow = styled(Row)`
  &.ant-row {
    .more {
      visibility: hidden;
    }
    &:hover {
      .more {
        visibility: visible;
      }
    }
  }
`;

const MainCard: React.FC<IMainCard> = ({ info }) => {
  const [openFinishModal, setOpenFinishMOdal] = useState<boolean>(false);
  const navigate = useNavigate();

  const onFinish = (e: { stopPropagation: () => void }): void => {
    e.stopPropagation();
    setOpenFinishMOdal(true);
  };

  const handleClickStatement = (id: string, type: string): void => {
    if (type === 'TRINING') {
      navigate(PATHS.COURSE_VIEW.replace(':id', id));
    }
    if (type === 'WORK') {
      navigate(PATHS.JOB_VIEW.replace(':id', id));
    }
    if (type === 'OTHER') {
      navigate(PATHS.OTHER_VIEW.replace(':id', id));
    }
  };

  const handleEdit = (): void => {
    if (info?.type === 'TRINING') {
      navigate(PATHS.COURSE_EDIT.replace(':id', info?.id));
    }
    if (info?.type === 'WORK') {
      navigate(PATHS.WORK_EDIT.replace(':id', info?.id));
    }
    if (info?.type === 'OTHER') {
      navigate(PATHS.OTHER_EDIT.replace(':id', info?.id));
    }
  };

  return (
    <>
      <AsnRow style={{ border: `0.5px solid ${WATER}`, borderRadius: 10, padding: 8, marginTop: 20 }} gutter={16}>
        <Col span={6} onClick={(): void => handleClickStatement(info?.id, info?.type)} style={{ cursor: 'pointer' }}>
          <Image
            preview={false}
            src={info?.companyPhoto}
            style={{ borderRadius: 4, objectFit: 'cover' }}
            height={'140px'}
            width={'100%'}
          />
        </Col>
        <Col span={14}>
          <Paragraph style={{ fontSize: 14, fontWeight: 600, height: 44, marginBottom: 0 }} ellipsis={{ rows: 2 }}>
            {info?.statementTitle}
          </Paragraph>
          <Space style={{ width: '100%', justifyContent: 'space-between', alignItems: 'flex-start' }}>
            <Paragraph style={{ marginBottom: 0, height: 44 }} ellipsis={{ rows: 2 }}>
              Ստեղծված՝ {info?.timeAgo}
            </Paragraph>
            <Space>
              <Badge
                text={
                  info?.status === 'BLOCKED'
                    ? 'Արգելափակված'
                    : info?.status === 'DONE'
                      ? 'Ավարտված'
                      : info?.status === 'INACTIVE'
                        ? 'Ընթացիկ'
                        : 'Նոր'
                }
                color={
                  info?.status === 'BLOCKED'
                    ? `${CORAL}`
                    : info?.status === 'DONE'
                      ? `${GRAY}`
                      : info?.status === 'INACTIVE'
                        ? `${AZURE}`
                        : `${GREEN}`
                }
                style={{ display: 'flex', alignItems: 'center', color: `${TEXT_COLOR}` }}
              />
            </Space>
          </Space>
          <Space style={{ width: '100%', justifyContent: 'space-between', height: 44, alignItems: 'flex-start' }}>
            <Row align={'middle'} style={{ gap: 8, flexWrap: 'nowrap' }}>
              <div>
                <LocationSvg />
              </div>
              <Paragraph style={{ marginBottom: 0 }} ellipsis={{ rows: 2 }}>
                {info?.statementLocation}
              </Paragraph>
            </Row>
            <Paragraph style={{ marginBottom: 0, textAlign: 'end' }} ellipsis={{ rows: 2 }}>
              {info?.statementFiled}
            </Paragraph>
          </Space>
        </Col>
        <Col span={4} style={{ position: 'relative', textAlign: 'end' }}>
          {info.status === 'ACTIVE' && (
            <>
              <Row justify={'end'}>
                <AsnButton
                  icon={<EditSvg />}
                  style={{ width: 40, justifyContent: 'center', marginBottom: 12 }}
                  onClick={handleEdit}
                ></AsnButton>
              </Row>
              <Button type='primary' onClick={onFinish}>
                Ավարտել
              </Button>
            </>
          )}
          {info.status === 'INACTIVE' && (
            <Button type='primary' onClick={onFinish}>
              Ավարտել
            </Button>
          )}
          <Text
            underline
            style={{
              color: `${AZURE}`,
              fontWeight: 600,
              position: 'absolute',
              bottom: 30,
              right: 9,
              cursor: 'pointer',
            }}
            className='more'
            onClick={(): void => handleClickStatement(info?.id, info?.type)}
          >
            Տեսնել ավելին
          </Text>
        </Col>
      </AsnRow>
      {openFinishModal && (
        <FinishModal open={openFinishModal} setOpen={setOpenFinishMOdal} id={info?.id} type={info?.type} />
      )}
    </>
  );
};
export default MainCard;
