import React, { useState } from 'react';
import { Input, Table, TableProps } from 'antd';

import { TablesContainer } from '../../../../pages/admin/requests/styled';
import { ICompanyListPending } from '../../../../api/admin/types/users/company';
import useGetCompany from '../../../../api/admin/users/useGetCompanys';
import { IPaginationRequests, ISortRequests } from '../../../../types/admin/requests';
import { ReactComponent as SearchIcon } from '../../../../assets/icons/search.svg';
import { useAuth } from '../../../../hooks/useAuth';

import { columnsRejected } from './columns';

const Rejected: React.FC = () => {
  const { user } = useAuth();
  const [searchCompany, setSearchCompany] = useState<string>('');
  const [tablePagination, setTablePagination] = useState<IPaginationRequests>({
    current: 1,
    pageSize: 10,
  });
  const [sortCompany, setSortCompany] = useState<ISortRequests>({
    column: '',
    direction: '',
  });

  const { data, isLoading } = useGetCompany({
    limit: tablePagination.pageSize,
    offset: (tablePagination.current - 1) * 10,
    statuses: ['REJECTED'],
    search: searchCompany === '' ? undefined : searchCompany,
    orderBy: sortCompany.column === '' && sortCompany.direction === '' ? undefined : { ...sortCompany },
  });

  const onSearchCompanyEnter = (e: React.SyntheticEvent<HTMLInputElement>): void => {
    setSearchCompany((e.target as HTMLInputElement).value.trim());
    setTablePagination({
      ...tablePagination,
      current: 1,
    });
  };

  const handleTableChange: TableProps<ICompanyListPending>['onChange'] = (pagination, filters, sorter) => {
    if ((sorter as { order: string }).order !== undefined) {
      setSortCompany({
        column: (sorter as { field: string }).field,
        direction: (sorter as { order: string }).order === 'descend' ? 'DESC' : 'ASC',
      });
    } else {
      setSortCompany({
        column: '',
        direction: '',
      });
    }
    setTablePagination({
      current: pagination.current,
      total: pagination.total,
      pageSize: 10,
    });
  };

  const permissionColumns =
    user?.permission === 'VIEW' && user.role === 'ADMIN'
      ? columnsRejected().filter((item) => item.key !== 'operation')
      : columnsRejected();
  return (
    <TablesContainer>
      <Input
        placeholder='Օրինակ'
        prefix={<SearchIcon />}
        style={{ width: '40%' }}
        onPressEnter={onSearchCompanyEnter}
      />
      <Table
        columns={permissionColumns}
        dataSource={data?.result}
        rowKey={(record): string => record?.id}
        loading={isLoading}
        pagination={{
          ...tablePagination,
          position: ['bottomCenter'],
          total: data?.count,
        }}
        onChange={handleTableChange}
      />
    </TablesContainer>
  );
};

export default Rejected;
