import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Col, Image, Space, Typography, Modal } from 'antd';
import dayjs from 'dayjs';
import { useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router';

import { AUTH_KEYS, COLORS, PATHS, STATUS } from '../../constants';
import AsnButton from '../Button';
import { ICompanyView } from '../../types/components';
import { IUserDetails } from '../../types/auth';
import ActionModal from '../ActionModal';
import { IBlockCompany } from '../../types/admin/users/company';
import useUpdateStatusCompany from '../../api/admin/users/useUpdateStatus';
import { ReactComponent as LocationSvg } from '../../assets/icons/location.svg';
import { ReactComponent as PhoneSvg } from '../../assets/icons/phone.svg';
import { ReactComponent as MailSvg } from '../../assets/icons/mail.svg';
import { ReactComponent as EditSvg } from '../../assets/icons/editBlue.svg';
import { ReactComponent as BackSvg } from '../../assets/icons/Back.svg';
import { useAuth } from '../../hooks/useAuth';

import { AboutCompany } from './styled';

const { Text, Paragraph, Link } = Typography;
const { AZURE, WHITE } = COLORS;

const width = window.innerWidth;

const CompanyView: React.FC<ICompanyView> = ({ company, publicRoute }) => {
  const { id } = useParams();
  const queryClient = useQueryClient();
  const { user } = useAuth();
  const [blockCompany, setBlockCompany] = useState<IBlockCompany>({
    id: '',
    title: '',
    status: '',
  });

  const localStorageUser = JSON.parse(localStorage.getItem(AUTH_KEYS.USER)) as IUserDetails;
  const role = localStorageUser?.role;
  const navigate = useNavigate();

  const { mutate: updateStatusCompanyById } = useUpdateStatusCompany({
    onSuccess: () => {
      setBlockCompany({
        id: '',
        title: '',
        status: '',
      });
      void queryClient.invalidateQueries(['/api/company/:id', id]);
    },
  });

  const onCloseModal = (): void => {
    setBlockCompany({
      id: '',
      title: '',
      status: '',
    });
  };

  const onBlockCompany = (): void => {
    updateStatusCompanyById({ id: blockCompany.id });
  };

  const handleBlock = (): void => {
    setBlockCompany({ id: company?.id, title: company.name, status: company?.user?.status });
  };

  return (
    <>
      <Row style={{ paddingTop: 35, position: 'relative', marginBottom: 20 }}>
        <Col span={22} pull={1} push={1} xxl={{ span: 18, pull: 3, push: 3 }}>
          <div style={{ cursor: 'pointer', marginBottom: 16 }} onClick={(): void => navigate(-1)}>
            <BackSvg />
          </div>
        </Col>
        <Col span={22} pull={1} push={1} xxl={{ span: 18, pull: 3, push: 3 }}>
          <Image
            preview={false}
            src={company?.backgroundPhoto}
            style={{ borderRadius: 10, objectFit: 'cover' }}
            height={'280px'}
            width={'100%'}
          />
          <div style={{ position: 'absolute', bottom: -80, left: 40 }}>
            <Image
              preview={false}
              src={company?.photo}
              width={160}
              height={160}
              style={{ borderRadius: 6, backgroundColor: WHITE, objectFit: 'contain' }}
            />
          </div>
        </Col>
      </Row>
      <Row style={{ width: '100%' }} align={'bottom'} gutter={[16, 0]}>
        <Col span={22} pull={1} push={1} xxl={{ span: 18, pull: 3, push: 3 }}>
          <Row style={{ marginLeft: width <= 667 ? 0 : 216, marginTop: width <= 667 ? 90 : 0 }} gutter={[16, 0]} wrap>
            <Col
              xxl={{ span: 12 }}
              xl={{ span: 12 }}
              lg={{ span: 12 }}
              md={{ span: 12 }}
              sm={{ span: 12 }}
              xs={{ span: 24 }}
              style={{ marginBottom: width <= 667 ? 24 : 0 }}
            >
              <Space direction='vertical'>
                <Paragraph style={{ fontSize: 20 }}>{company?.name}</Paragraph>
                <Row align={'middle'} style={{ gap: 8 }} wrap={false}>
                  <div>
                    <LocationSvg />
                  </div>
                  <Paragraph style={{ marginBottom: 0 }} ellipsis={{ rows: 2, expandable: true }}>
                    {company?.region?.title} {company?.location}
                  </Paragraph>
                </Row>
                <Row align={'middle'} style={{ gap: 8 }} wrap={false}>
                  <div>
                    <PhoneSvg />
                  </div>
                  <Paragraph style={{ marginBottom: 0 }} ellipsis={{ rows: 2, expandable: true }}>
                    {company?.phone}
                  </Paragraph>
                </Row>
                <Row align={'middle'} style={{ gap: 8 }} wrap={false}>
                  <div>
                    <MailSvg />
                  </div>
                  <Paragraph
                    underline
                    style={{ marginBottom: 0, cursor: 'pointer' }}
                    ellipsis={{ rows: 2, expandable: true }}
                    onClick={(): Window | null => window.open(`mailto:${company?.user?.email}`, '_blank')}
                  >
                    {company?.user?.email}
                  </Paragraph>
                </Row>
              </Space>
            </Col>
            <Col
              xxl={{ span: 12 }}
              xl={{ span: 12 }}
              lg={{ span: 12 }}
              md={{ span: 12 }}
              sm={{ span: 12 }}
              xs={{ span: 24 }}
              style={{ textAlign: width <= 667 ? 'start' : 'end' }}
            >
              <Space direction='vertical' style={{ alignItems: 'baseline' }}>
                {role === 'COMPANY' && !publicRoute ? (
                  <AsnButton
                    icon={<EditSvg />}
                    style={{ marginBottom: '1em' }}
                    onClick={(): void => navigate(PATHS.COMPANY_MYINFO_EDIT)}
                  >
                    Խմբագրել
                  </AsnButton>
                ) : null}
                {!(user?.permission === 'VIEW' && user.role === 'ADMIN') && (
                  <>
                    {role === 'SUPER_ADMIN' || role === 'ADMIN' ? (
                      <>
                        {company?.user?.status === STATUS.ACTIVE ? (
                          <AsnButton type='primary' style={{ marginBottom: '1em' }} onClick={handleBlock}>
                            Արգելափակել
                          </AsnButton>
                        ) : null}
                        {company?.user?.status === STATUS.BLOCKED ? (
                          <AsnButton type='primary' style={{ marginBottom: '1em' }} onClick={handleBlock}>
                            Ապաարգելափակել
                          </AsnButton>
                        ) : null}
                      </>
                    ) : null}
                  </>
                )}
                <Text
                  style={{ cursor: 'pointer' }}
                  onClick={(): void => navigate(`${PATHS.COMPANY_ACTIVE_STATEMENTS.replace(':id', company?.id)}`)}
                >
                  {company?.activeStatementsCount} Ակտիվ հայտարարություն
                </Text>
                <Text>{company?.numberOfEmployees} Աշխատակից</Text>
                <Text>
                  Ընկերությունը ստեղծվել է՝
                  {dayjs(company?.creationDate).utc().format('YYYY')}
                  թ․
                </Text>
                {company?.website && (
                  <Link
                    underline
                    target='_blank'
                    href={company?.website}
                    style={{ color: `${AZURE}`, marginBottom: 0, cursor: 'pointer', fontSize: '16px' }}
                  >
                    Վեբ կայք
                  </Link>
                )}
              </Space>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row style={{ padding: '32px 0px' }}>
        <Col span={22} pull={1} push={1} xxl={{ span: 18, pull: 3, push: 3 }}>
          {company?.description && (
            <>
              <Paragraph style={{ fontSize: 20, fontWeight: 600 }}>Ընկերության մասին</Paragraph>
              <AboutCompany className='list' dangerouslySetInnerHTML={{ __html: company?.description || '' }} />
            </>
          )}
          {company?.companyValues && (
            <>
              <Paragraph style={{ fontSize: 20, fontWeight: 600 }}>Ընկերության Արժեքները</Paragraph>
              <AboutCompany className='list' dangerouslySetInnerHTML={{ __html: company?.companyValues || '' }} />
            </>
          )}
        </Col>
      </Row>
      <Modal centered open={!!blockCompany.id} onCancel={onCloseModal} footer={false}>
        <ActionModal
          text={
            blockCompany.status === STATUS.ACTIVE
              ? `Համոզվա՞ծ եք, որ ցանկանում եք Արգելափակել "${blockCompany.title}" կազմակերպությանը`
              : `Համոզվա՞ծ եք, որ ցանկանում եք Ապարգելափակել "${blockCompany.title}" կազմակերպությանը`
          }
          resolveFn={onBlockCompany}
          rejectFn={onCloseModal}
        />
      </Modal>
    </>
  );
};

export default CompanyView;
