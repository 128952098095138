import React, { useState } from 'react';
import { Row, Col, Image, Space, Typography, PaginationProps } from 'antd';
import { SwiperSlide } from 'swiper/react';
import Swiper, { Pagination, Navigation, EffectCoverflow, Controller } from 'swiper';

import { AsnSwiper, SwiperPagination } from '../../../components';
import { COLORS } from '../../../constants';
import 'swiper/css';
import 'swiper/css/bundle';
import 'swiper/swiper-bundle.min.css';
import { ISuccessStories } from '../../../types/landing';

import SwiperPrevButton from './SwiperPrevStory';
import SwiperNextStory from './SwiperNextStory';
import SuccessStory from './SuccessStory';

const { Title, Paragraph, Link } = Typography;
const { ORANGE_DARK, TEXT_COLOR } = COLORS;

const SuccessStories: React.FC<ISuccessStories> = ({ successStories, width }) => {
  const [page, setPage] = useState<number>(1);
  const swiperRef = React.useRef<null | Swiper>(null);

  const onBeforeInit = (Swiper: Swiper): void => {
    swiperRef.current = Swiper;
  };

  const itemRender: PaginationProps['itemRender'] = (_, type, originalElement) => {
    const handleNext = (): void => {
      if (page === successStories?.length) {
        setPage(1);
      } else {
        setPage(page + 1);
      }
      if (swiperRef.current) {
        swiperRef?.current.slideNext();
      }
    };
    const handleBack = (): void => {
      if (page === 1) {
        setPage(successStories?.length);
      } else {
        setPage(page - 1);
      }
      if (swiperRef.current) {
        swiperRef?.current.slidePrev();
      }
    };

    if (type === 'prev') {
      return (
        <div>
          <SwiperPrevButton handleBack={handleBack} />
        </div>
      );
    }
    if (type === 'next') {
      return (
        <div>
          <SwiperNextStory handleNext={handleNext} />
        </div>
      );
    }
    return originalElement;
  };

  return (
    <Row
      align={'middle'}
      justify={'center'}
      style={{ width: '100%', padding: '32px 4% 10px 4%', position: 'relative' }}
    >
      <Col xxl={{ span: 16 }} xl={{ span: 17 }} lg={{ span: 20 }} md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }}>
        {width <= 667 && (
          <Row align={'middle'} justify={'center'} style={{ width: '100%' }}>
            <AsnSwiper
              slidesPerView={1}
              spaceBetween={30}
              loop={true}
              centeredSlides={true}
              centeredSlidesBounds={true}
              modules={[Pagination, Navigation]}
              style={{ paddingBottom: 10, width: '100%' }}
              pagination={false}
              onBeforeInit={onBeforeInit}
              allowTouchMove={true}
            >
              {successStories?.map((story, i) => (
                <SwiperSlide key={i}>
                  <Space direction='vertical' align='center'>
                    <Space direction='vertical' align='center'>
                      <Title level={4} style={{ textAlign: 'center' }}>
                        {story?.title}
                      </Title>
                      <div style={{ textAlign: 'center', marginBottom: 12 }}>
                        <Link href={story?.companyLink} target='_blank' underline style={{ color: `${ORANGE_DARK}` }}>
                          {story?.companyName}
                        </Link>
                      </div>
                      <Paragraph style={{ textAlign: 'center' }}>
                        {story?.story}{' '}
                        {story?.link && (
                          <Link href={story?.link} target='_blank' underline style={{ color: `${TEXT_COLOR}` }}>
                            Տեսնել ավելին
                          </Link>
                        )}
                      </Paragraph>
                    </Space>
                    <Image
                      src={story?.photo}
                      preview={false}
                      className='swiper_img'
                      width={200}
                      height={200}
                      style={{ border: ` 3px solid ${ORANGE_DARK}` }}
                    />
                  </Space>
                </SwiperSlide>
              ))}
            </AsnSwiper>
          </Row>
        )}
        {width > 667 && (
          <AsnSwiper
            spaceBetween={30}
            loop={true}
            effect='coverflow'
            centeredSlides={true}
            modules={[EffectCoverflow, Pagination, Navigation, Controller]}
            coverflowEffect={{ rotate: 0, stretch: 0, depth: 100, modifier: 1, slideShadows: false }}
            style={{ width: '100%' }}
            className='coverflow padding'
            pagination={{
              clickable: true,
              type: 'custom',
            }}
            slideToClickedSlide
            breakpoints={{
              300: {
                slidesPerView: 1,
              },
              768: {
                slidesPerView: successStories?.length <= 2 ? 1 : 3,
              },
              992: {
                slidesPerView:
                  successStories?.length <= 2 ? 1 : successStories?.length >= 3 && successStories?.length <= 4 ? 3 : 4,
              },
            }}
          >
            {successStories?.map((story, i) => (
              <SwiperSlide key={i}>
                <SuccessStory
                  title={story?.title}
                  companyLink={story?.companyLink}
                  companyName={story?.companyName}
                  story={story?.story}
                  link={story?.link}
                />
                <Image src={story?.photo} preview={false} className='swiper_img' />
              </SwiperSlide>
            ))}
            {successStories?.length === 3 &&
              width > 768 &&
              successStories?.map((story, i) => (
                <SwiperSlide key={i}>
                  <SuccessStory
                    title={story?.title}
                    companyLink={story?.companyLink}
                    companyName={story?.companyName}
                    story={story?.story}
                    link={story?.link}
                  />
                  <Image src={story?.photo} preview={false} className='swiper_img' />
                </SwiperSlide>
              ))}
            {successStories?.length === 3 &&
              width > 768 &&
              successStories?.map((story, i) => (
                <SwiperSlide key={i}>
                  <SuccessStory
                    title={story?.title}
                    companyLink={story?.companyLink}
                    companyName={story?.companyName}
                    story={story?.story}
                    link={story?.link}
                  />
                  <Image src={story?.photo} preview={false} className='swiper_img' />
                </SwiperSlide>
              ))}
            {successStories?.length >= 4 &&
              successStories?.length < 10 &&
              width > 768 &&
              successStories?.map((story, i) => (
                <SwiperSlide key={i}>
                  <SuccessStory
                    title={story?.title}
                    companyLink={story?.companyLink}
                    companyName={story?.companyName}
                    story={story?.story}
                    link={story?.link}
                  />
                  <Image src={story?.photo} preview={false} className='swiper_img' />
                </SwiperSlide>
              ))}
            {successStories?.length !== 1 && width > 667 && (
              <Row justify={'center'} style={{ width: '100%', paddingTop: 40 }}>
                <SwiperPagination
                  total={successStories?.length}
                  showSizeChanger={false}
                  current={page}
                  itemRender={itemRender}
                  defaultPageSize={1}
                  disabled={false}
                />
              </Row>
            )}
          </AsnSwiper>
        )}
      </Col>
    </Row>
  );
};

export default SuccessStories;
