/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Row, Col, Select, Input, DatePicker, Typography, Image, Space } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CloseOutlined } from '@ant-design/icons';
import { RangePickerProps } from 'antd/es/date-picker';

import { handleEditorErrors } from '../../../helpers';
import { AsnForm, AsnButton } from '../../../components';
import { ICourseForm } from '../../../types/company';
import { COLORS } from '../../../constants';

const { Paragraph } = Typography;
const { TEXT_COLOR } = COLORS;

const CourseForm: React.FC<ICourseForm> = ({ error, setError, statement, data }) => {
  const [formatName, setFormatName] = useState('');
  const form = AsnForm.useFormInstance();
  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    const endDateValue = form.getFieldValue('startDate') as Dayjs;
    return current && (current < dayjs().subtract(1, 'day') || (endDateValue && current > endDateValue));
  };

  useEffect(() => {
    if (statement) {
      setFormatName(statement?.trainingStatement?.format.title);
    }
  }, [statement]);

  return (
    <>
      <Row>
        <Col span={16}>
          <AsnForm.Item
            label='Դասընթացի Անվանումը'
            name={'title'}
            rules={[{ required: true }, { min: 2, max: 50 }]}
            normalize={(value: string): string => value.trimStart().replace(/\s+/g, ' ')}
            initialValue={statement?.trainingStatement?.title}
          >
            <Input style={{ maxWidth: 630 }} />
          </AsnForm.Item>
          <AsnForm.Item
            label='Ոլորտ'
            name={'filedStudyId'}
            rules={[{ required: true }]}
            initialValue={statement?.trainingStatement?.filedStudy?.id}
          >
            <Select style={{ maxWidth: 630 }} options={data?.filedOfStudy} />
          </AsnForm.Item>
          <AsnForm.Item
            label='Դասընթացի մասին'
            name={'description'}
            rules={[{ required: true }, { min: 10, max: 500 }]}
            initialValue={statement?.trainingStatement?.description}
          >
            <div style={{ maxWidth: 630 }} className={error?.description ? 'editor_error' : 'editor'}>
              <CKEditor
                editor={ClassicEditor}
                config={{
                  toolbar: ['bulletedList'],
                }}
                onChange={(event, editor): void => {
                  const data = editor?.getData();
                  form?.setFieldValue('description', data.trimStart().replace(/(&nbsp;|\s)+/g, ' '));
                  const hasError = handleEditorErrors(form, 'description', data, 10, 500);
                  setError({ ...error, description: hasError });
                }}
                data={statement?.trainingStatement?.description}
              />
            </div>
          </AsnForm.Item>
        </Col>
      </Row>
      <Row gutter={40}>
        <Col span={8}>
          <AsnForm.Item
            label='Դասընթացի մեկնարկը'
            name={'startDate'}
            rules={[{ required: true }]}
            initialValue={
              statement?.trainingStatement?.startDate ? dayjs(statement?.trainingStatement?.startDate) : undefined
            }
          >
            <DatePicker
              style={{ width: '100%' }}
              placeholder=''
              disabledDate={(current: Dayjs): boolean => current < dayjs().subtract(1, 'day')}
            />
          </AsnForm.Item>
        </Col>
        <Col span={8}>
          <AsnForm.Item
            label='Դասընթացի Տևողությունը'
            name={'duration'}
            rules={[{ required: true }, { min: 2, max: 50 }]}
            normalize={(value: string): string => value.trimStart().replace(/\s+/g, ' ')}
            initialValue={statement?.trainingStatement?.duration}
          >
            <Input />
          </AsnForm.Item>
        </Col>
        <Col span={8}>
          <AsnForm.Item
            label='Դասաժամեր'
            name={'classHours'}
            rules={[{ required: true }, { min: 2, max: 50 }]}
            normalize={(value: string): string => value.trimStart().replace(/\s+/g, ' ')}
            initialValue={statement?.trainingStatement?.classHours}
          >
            <Input />
          </AsnForm.Item>
        </Col>
      </Row>
      <Row gutter={40}>
        <Col span={8}>
          <AsnForm.Item
            label='Դիմելու վերջնաժամկետը'
            name={'applicationDeadline'}
            rules={[{ required: true }]}
            initialValue={
              statement?.trainingStatement?.applicationDeadline
                ? dayjs(statement?.trainingStatement?.applicationDeadline)
                : undefined
            }
          >
            <DatePicker style={{ width: '100%' }} placeholder='' disabledDate={disabledDate} />
          </AsnForm.Item>
        </Col>
        <Col span={8}>
          <AsnForm.Item
            label='Դասընթացի լեզու'
            name={'languageId'}
            rules={[{ required: true }]}
            initialValue={statement?.trainingStatement?.language?.id}
          >
            <Select options={data?.courseLanguage} />
          </AsnForm.Item>
        </Col>
        <Col span={8}>
          <AsnForm.Item
            label='Ձևաչափ'
            name={'formatId'}
            rules={[{ required: true }]}
            initialValue={statement?.trainingStatement?.format?.id}
          >
            <Select options={data?.courseFormat} onSelect={(value, data): void => setFormatName(data.label)} />
          </AsnForm.Item>
        </Col>
      </Row>
      <Row gutter={40}>
        <Col span={8}>
          <AsnForm.Item
            label='Դասընթացի Մակարդակ'
            name={'levelId'}
            rules={[{ required: true }]}
            initialValue={statement?.trainingStatement?.level?.id}
          >
            <Select options={data?.courseLevel} />
          </AsnForm.Item>
        </Col>
        {formatName === 'Առցանց' ? null : (
          <Col span={8}>
            <AsnForm.Item
              label='Հասցե'
              name={'location'}
              rules={[{ required: true }, { min: 9, max: 100 }]}
              normalize={(value: string): string => value.trimStart().replace(/\s+/g, ' ')}
              initialValue={statement?.trainingStatement?.location}
            >
              <Input />
            </AsnForm.Item>
          </Col>
        )}
        <Col span={8}>
          <AsnForm.Item
            label='Հեռախոս'
            name={'phone'}
            rules={[{ required: true }, { min: 8, max: 15 }]}
            normalize={(value: string): string => value?.replace(/[^0-9]/g, '')}
            initialValue={statement?.trainingStatement?.phone}
          >
            <Input />
          </AsnForm.Item>
        </Col>
      </Row>
      <Row gutter={40}>
        {formatName === 'Առցանց' ? null : (
          <Col span={8}>
            <AsnForm.Item
              label='Գտնվելու վայրը (Մարզ)'
              name={'regionId'}
              rules={[{ required: true }]}
              normalize={(value: string): string => value.trimStart().replace(/\s+/g, ' ')}
              initialValue={statement?.trainingStatement?.region?.id}
            >
              <Select options={data?.regions} />
            </AsnForm.Item>
          </Col>
        )}
      </Row>
      <div
        style={{
          borderRadius: '2px',
          background:
            'linear-gradient(90deg, rgba(31, 130, 255, 0.20) 0%, #1F82FF 51.2%, rgba(31, 130, 255, 0.20) 100%)',
          height: '2px',
          marginBottom: 32,
        }}
      />
      <Row>
        <Col span={16}>
          <AsnForm.Item
            label='Էլ․հասցե'
            name={'registrationLink'}
            rules={[
              { required: true },
              { type: 'email', message: 'Խնդրում ենք մուտքագրել էլ․ հասցեն հետևյալ ֆորմատով՝ yourname@domain.com' },
            ]}
            initialValue={statement?.trainingStatement?.registrationLink}
          >
            <Input style={{ maxWidth: 630 }} />
          </AsnForm.Item>
          <AsnForm.Item
            label='Ծրագիր'
            name={'program'}
            rules={[{ required: true }, { min: 20, max: 5000 }]}
            initialValue={statement?.trainingStatement?.program}
          >
            <div style={{ maxWidth: 630 }} className={error?.program ? 'editor_error' : 'editor'}>
              <CKEditor
                editor={ClassicEditor}
                config={{
                  toolbar: ['bulletedList'],
                }}
                onChange={(event, editor): void => {
                  const data = editor.getData();
                  form.setFieldValue('program', data.trimStart().replace(/(&nbsp;|\s)+/g, ' '));
                  const hasError = handleEditorErrors(form, 'program', data, 20, 5000);
                  setError({ ...error, program: hasError });
                }}
                data={statement?.trainingStatement?.program}
              />
            </div>
          </AsnForm.Item>
        </Col>
      </Row>
      <AsnForm.List
        name='topics'
        initialValue={statement ? [...(statement?.trainingStatement?.topics as never)] : ['']}
      >
        {(fields, { add, remove }): JSX.Element => (
          <>
            <Row gutter={40}>
              {fields.map(({ key, name, ...restField }) => (
                <Col span={8} key={key} style={{ position: 'relative' }}>
                  <AsnForm.Item
                    {...restField}
                    label='Ի՞նչ եք սովորելու'
                    name={[name]}
                    rules={[{ required: name === 0 }, { max: 40 }]}
                    normalize={(value: string): string => value.trimStart().replace(/\s+/g, ' ')}
                  >
                    <Input />
                  </AsnForm.Item>
                  {name !== 0 && (
                    <CloseOutlined
                      onClick={(): void => {
                        remove(name);
                      }}
                      style={{ position: 'absolute', right: '-5px', top: 20 }}
                    />
                  )}
                </Col>
              ))}
            </Row>
            <AsnButton
              className='add'
              onClick={(): void => add()}
              style={{ marginBottom: 32, color: `${TEXT_COLOR}` }}
              disabled={fields?.length === 15}
            >
              + Ավելացնել դաշտ
            </AsnButton>
          </>
        )}
      </AsnForm.List>
      <div
        style={{
          borderRadius: '2px',
          background:
            'linear-gradient(90deg, rgba(31, 130, 255, 0.20) 0%, #1F82FF 51.2%, rgba(31, 130, 255, 0.20) 100%)',
          height: '2px',
          marginBottom: 32,
        }}
      />
      <AsnForm.List
        name='teacherIds'
        initialValue={
          (statement?.trainingStatement?.teachers?.map((teacher) => teacher?.id) || []).length > 0
            ? [...statement.trainingStatement.teachers.map((teacher) => teacher?.id)]
            : ['']
        }
      >
        {(fields, { add, remove }): JSX.Element => (
          <>
            <Row gutter={40}>
              {fields.map(({ key, name, ...restField }) => (
                <Col span={8} key={key} style={{ position: 'relative' }}>
                  <AsnForm.Item {...restField} label='Ընտրել դասավանդող' name={[name]}>
                    <Select
                      options={data?.teachers?.map((teacher) => {
                        return {
                          label: (
                            <>
                              <Space align='center' style={{ justifyContent: 'center' }}>
                                <Image
                                  preview={false}
                                  src={teacher?.photo}
                                  width={32}
                                  height={32}
                                  style={{ borderRadius: '50%' }}
                                />
                                <Paragraph style={{ marginBottom: 0 }}>{teacher?.fullName}</Paragraph>
                              </Space>
                            </>
                          ),
                          value: teacher?.id,
                        };
                      })}
                    />
                  </AsnForm.Item>
                  {name !== 0 && (
                    <CloseOutlined
                      onClick={(): void => {
                        remove(name);
                      }}
                      style={{ position: 'absolute', right: '-5px', top: 20 }}
                    />
                  )}
                </Col>
              ))}
            </Row>
            <AsnButton
              className='add'
              onClick={(): void => add()}
              style={{ marginBottom: 32, color: `${TEXT_COLOR}` }}
              disabled={fields?.length === 3}
            >
              + Ավելացնել դասավանդող
            </AsnButton>
          </>
        )}
      </AsnForm.List>
    </>
  );
};

export default CourseForm;
