import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Col, Image, Typography, Space } from 'antd';
import dayjs from 'dayjs';

import { COLORS, PATHS, dateFormat } from '../../constants';
import { ReactComponent as LocationSvg } from '../../assets/icons/locationOrange.svg';
import { ReactComponent as StatementCountSvg } from '../../assets/icons/statementCount.svg';
import { TGetAllCompanyPublic } from '../../types/api/public';
import { useAuth } from '../../hooks/useAuth';

import { AsnRow, Content, SpaceContainer } from './styled';

const { Paragraph, Text } = Typography;
const { AZURE } = COLORS;

const PublicCompanyCard: React.FC<{ info: TGetAllCompanyPublic }> = ({ info }) => {
  const navigate = useNavigate();
  const { user } = useAuth();

  const handleClickStatement = (id: string): void => {
    navigate(`${PATHS.COMPANY_VIEW_PUBLIC}/?id=${id}`);
  };
  return (
    <>
      <AsnRow>
        <Col md={{ span: 6 }} xs={{ span: 24 }} sm={{ span: 24 }} onClick={(): void => handleClickStatement(info?.id)}>
          <Image
            preview={false}
            src={info?.companyPhoto}
            style={{ borderRadius: 4, objectFit: 'contain' }}
            height={'120px'}
            width={'100%'}
          />
        </Col>
        <Content md={{ span: 14 }} sm={{ span: 24 }} xs={{ span: 24 }}>
          <Paragraph
            style={{ fontSize: 14, fontWeight: 600, height: 44, marginBottom: 0, color: AZURE }}
            ellipsis={{ rows: 2 }}
          >
            {info?.companyName}
          </Paragraph>
          <SpaceContainer>
            <Row align={'middle'} style={{ gap: 8, flexWrap: 'nowrap', marginTop: '10px' }}>
              <div
                style={{
                  height: '17px',
                }}
              >
                <LocationSvg />
              </div>
              <Paragraph style={{ marginBottom: 0 }} ellipsis={{ rows: 2 }}>
                {info?.location}
              </Paragraph>
            </Row>
            <Space style={{ width: '100%', flexDirection: 'column', alignItems: 'flex-start' }}>
              <Space
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
                direction='horizontal'
              >
                <div
                  style={{
                    height: '17px',
                  }}
                >
                  <StatementCountSvg />
                </div>
                <Paragraph
                  underline
                  style={{ marginBottom: 0, cursor: 'pointer' }}
                  ellipsis={{ rows: 2 }}
                  onClick={(): void => navigate(PATHS.COMPANY_ACTIVE_STATEMENTS.replace(':id', info.id))}
                >
                  {info?.statementsCount} ակտիվ հայտարարություն
                </Paragraph>
              </Space>
              {user ? (
                <Paragraph style={{ marginBottom: 0, height: 44 }} ellipsis={{ rows: 2 }}>
                  Ստեղծված՝ {info?.createdAt ? dayjs(info?.createdAt).utc().format(dateFormat) : null}
                </Paragraph>
              ) : null}
            </Space>
          </SpaceContainer>
        </Content>
        <Col md={{ span: 4 }} xs={{ span: 24 }} sm={{ span: 24 }} style={{ position: 'relative', textAlign: 'end' }}>
          <Text
            underline
            style={{
              color: `${AZURE}`,
              fontWeight: 600,
              position: 'absolute',
              bottom: 20,
              right: 9,
              cursor: 'pointer',
            }}
            className='more'
            onClick={(): void => handleClickStatement(info?.id)}
          >
            Տեսնել ավելին
          </Text>
        </Col>
      </AsnRow>
    </>
  );
};

export default PublicCompanyCard;
