import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Spinner, CompanyView } from '../../../components';
import { useGetCompanyById } from '../../../api';
import { useAuth } from '../../../hooks/useAuth';
import { PATHS } from '../../../constants';

const MyInfo: React.FC = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { data: company, isFetching: isFetchingCompany } = useGetCompanyById(user?.id, {
    enabled: Boolean(user?.id),
  });

  if (isFetchingCompany) {
    return <Spinner />;
  }
  if (!company?.typeId) {
    navigate(PATHS.COMPANY_MYINFO_EDIT);
  }

  return (
    <>
      <CompanyView company={company} />
    </>
  );
};

export default MyInfo;
