import React, { useEffect } from 'react';
import { Row, Col, Select, Input, DatePicker, Space, Upload, Image } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import styled from 'styled-components';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import { AsnButton, AsnForm } from '../../../components';
import { IOtherForm } from '../../../types/company';
import { uploadPropsImage } from '../../../helpers';
import { ReactComponent as AddPhotoSvg } from '../../../assets/icons/addPhoto.svg';
import { ReactComponent as DeleteSvg } from '../../../assets/icons/delete.svg';

import { FormContentOther } from './styled';

const AsnSpace = styled(Space)`
  .ant-space-item {
    width: 100%;
  }
`;

const OtherForm: React.FC<IOtherForm> = ({ data, statement, urlPhoto, setUrlPhoto }) => {
  const form = AsnForm.useFormInstance();
  useEffect(() => {
    setUrlPhoto(statement?.otherStatement?.photo);
    form.setFieldsValue({
      program: statement?.otherStatement?.program || '',
    });
  }, [statement]);
  return (
    <>
      <Row>
        <Col span={16}>
          <AsnForm.Item
            label='Հայտարարության վերնագիրը'
            name={'title'}
            rules={[{ required: true }, { min: 2, max: 100 }]}
            normalize={(value: string): string => value.trimStart().replace(/\s+/g, ' ')}
            initialValue={statement?.otherStatement?.title}
          >
            <Input style={{ maxWidth: 630 }} />
          </AsnForm.Item>
          <AsnSpace style={{ width: '100%', maxWidth: 630 }} size={40}>
            <AsnForm.Item
              label='Դիմելու վերջնաժամկետ'
              name={'applicationDeadline'}
              rules={[{ required: true }]}
              initialValue={
                statement?.otherStatement?.applicationDeadline
                  ? dayjs(statement?.otherStatement?.applicationDeadline)
                  : undefined
              }
            >
              <DatePicker
                style={{ width: '100%' }}
                placeholder=''
                disabledDate={(current: Dayjs): boolean => current < dayjs().subtract(1, 'day')}
              />
            </AsnForm.Item>
            <AsnForm.Item
              label='Գտնվելու վայրը (Մարզ)'
              name={'regionId'}
              rules={[{ required: true }]}
              normalize={(value: string): string => value.trimStart().replace(/\s+/g, ' ')}
              initialValue={statement?.otherStatement?.region?.id}
            >
              <Select options={data?.regions} />
            </AsnForm.Item>
          </AsnSpace>
        </Col>
      </Row>
      <Row>
        <Col span={16}>
          <AsnSpace style={{ width: '100%', maxWidth: 630 }} size={40}>
            <AsnForm.Item
              label='Հասցե'
              name={'location'}
              rules={[{ required: true }, { min: 9, max: 100 }]}
              normalize={(value: string): string => value.trimStart().replace(/\s+/g, ' ')}
              initialValue={statement?.otherStatement?.location}
            >
              <Input />
            </AsnForm.Item>
            <AsnForm.Item
              label='Հեռախոս'
              name={'phone'}
              rules={[{ required: true }, { min: 8, max: 15 }]}
              normalize={(value: string): string => value?.replace(/[^0-9]/g, '')}
              initialValue={statement?.otherStatement?.phone}
            >
              <Input />
            </AsnForm.Item>
          </AsnSpace>
        </Col>
      </Row>
      <Row>
        <Col span={16}>
          <AsnSpace style={{ width: '100%', maxWidth: 630 }} size={40}>
            <AsnForm.Item
              label='Անցկացման օր'
              name={'startDate'}
              initialValue={
                statement?.otherStatement?.startDate ? dayjs(statement?.otherStatement?.startDate) : undefined
              }
            >
              <DatePicker
                style={{ width: '100%' }}
                placeholder=''
                disabledDate={(current: Dayjs): boolean => current < dayjs().subtract(1, 'day')}
              />
            </AsnForm.Item>
            <AsnForm.Item
              label='Էլ․հասցե'
              name={'registrationLink'}
              rules={[
                { required: true },
                {
                  type: 'email',
                  message: 'Խնդրում ենք մուտքագրել էլ․ հասցեն հետևյալ ֆորմատով՝ yourname@domain.com',
                },
              ]}
              initialValue={statement?.otherStatement?.registrationLink}
            >
              <Input style={{ maxWidth: 630 }} />
            </AsnForm.Item>
          </AsnSpace>
        </Col>
      </Row>
      <Row>
        <Col span={16}>
          <AsnForm.Item
            label='Ծրագիր'
            name='program'
            rules={[
              { required: true, message: 'Խնդրում ենք լրացնել դաշտը' },
              { min: 20, message: 'Ծրագիրը պետք է ունենա առնվազն 20 նիշ' },
              { max: 5000, message: 'Պետք է ունենա ոչ ավել քան 5000 նիշ' },
            ]}
            initialValue={statement?.otherStatement?.program}
          >
            <div style={{ maxWidth: 630 }} className='editorAll'>
              <CKEditor
                editor={ClassicEditor}
                config={{
                  toolbar: [],
                }}
                data={statement?.otherStatement?.program}
                onChange={(event, editor): void => {
                  const data = editor.getData();
                  form.setFieldsValue({ program: data });
                  void form.validateFields(['program']);
                }}
              />
            </div>
          </AsnForm.Item>
        </Col>
      </Row>
      <Row>
        <Col span={16}>
          <AsnForm.Item
            label='Ինչ ենք մենք առաջարկում (Ընկերության մասին)'
            name={'whatWeOffer'}
            rules={[
              { required: true, message: ' Խնդրում ենք լրացնել դաշտը' },
              { min: 2, message: ' Պետք է ունենա առնվազն 2 նիշ' },
              { max: 1000, message: 'Պետք է ունենա ոչ ավել քան 1000 նիշ' },
            ]}
            initialValue={statement?.otherStatement?.whatWeOffer}
          >
            <div style={{ maxWidth: 630 }} className='editorAll'>
              <CKEditor
                editor={ClassicEditor}
                config={{
                  toolbar: [],
                }}
                data={statement?.otherStatement?.whatWeOffer}
                onChange={(event, editor): void => {
                  const data = editor.getData();
                  form.setFieldsValue({ whatWeOffer: data });
                }}
              />
            </div>
          </AsnForm.Item>
        </Col>
      </Row>
      <Row>
        <Col span={16}>
          <FormContentOther>
            <AsnForm.Item name='photo' label='Կցել լուսանկարը'>
              <Upload {...uploadPropsImage(setUrlPhoto)} style={{ display: 'flex', flexDirection: 'row' }}>
                {!urlPhoto ? (
                  <AddPhotoSvg />
                ) : (
                  <Image preview={false} src={urlPhoto} width={'20vw'} height={228} style={{ borderRadius: 6 }} />
                )}
              </Upload>
              {urlPhoto && (
                <AsnButton type='text' style={{ padding: '0px' }} onClick={(): void => setUrlPhoto('')}>
                  <DeleteSvg />
                </AsnButton>
              )}
            </AsnForm.Item>
          </FormContentOther>
        </Col>
      </Row>
    </>
  );
};

export default OtherForm;
