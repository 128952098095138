import React, { useState, useEffect } from 'react';
import { Form, Image, Input, Upload, message } from 'antd';
import { useNavigate } from 'react-router-dom';

import AsnButton from '../Button';
import { COLORS, PATHS } from '../../constants';
import { uploadPropsImage } from '../../helpers';
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete.svg';
import { ReactComponent as AddPhotoSvg } from '../../assets/icons/addPhoto.svg';
import useGetLecturersById from '../../api/company/Lecturers/useGetLecturersById';
import useCreateLecturers from '../../api/company/Lecturers/useCreateLecturers';
import useUpdateLecturers from '../../api/company/Lecturers/useUpdateLecturers';
import Spinner from '../Spinner';

import { AddButtonContainer, ButtonsContainer, FormContent, FormSpace, ListContainer } from './styled';
const { TextArea } = Input;

const { GRAY, DARK_GRAY } = COLORS;

export interface IFormTeacher {
  fullName: string;
  profession: string;
  experience: string;
  photo: string | undefined;
  websites: string[];
}

const LecturersForm: React.FC<{ edit?: string }> = ({ edit }) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [urlPhoto, setUrlPhoto] = useState<string | undefined>(undefined);
  const [isChanged, setIsChanged] = useState<boolean>(false);
  const [disabledButton, setDisabledButton] = useState(false);
  const {
    data: { result },
    isFetching,
  } = useGetLecturersById(edit, {
    enabled: Boolean(edit),
  });
  const { mutate: createLecturersFn } = useCreateLecturers();
  const { mutate: updateLecturersById } = useUpdateLecturers({
    onSuccess: () => {
      navigate(PATHS.COMPANY_LECTURERS);
      setDisabledButton(false);
    },
    onError: (error: string) => {
      setDisabledButton(false);
      void message.error(error as unknown as string, 2);
    },
  });

  const onFinish = (values: IFormTeacher): void => {
    const validWebsites = values.websites.filter((website, index) => {
      if (index === 0) {
        return [''];
      } else {
        return website?.length > 10;
      }
    });

    setDisabledButton(true);
    if (edit) {
      if (isChanged) {
        updateLecturersById({
          id: edit,
          lecturersData: {
            ...values,
            photo: urlPhoto ? urlPhoto : undefined,
            websites: { links: validWebsites },
          },
        });
      } else {
        navigate(PATHS.COMPANY_LECTURERS);
      }
      setIsChanged(false);
    } else {
      createLecturersFn(
        { ...values, websites: { links: validWebsites }, photo: urlPhoto ?? '' },
        {
          onSuccess: () => {
            navigate(PATHS.COMPANY_LECTURERS);
            setDisabledButton(false);
          },
          onError: (error) => {
            setDisabledButton(false);
            void message.error(error as unknown as string, 2);
          },
        },
      );
      setIsChanged(false);
    }
  };

  useEffect(() => {
    if (result !== undefined) {
      form.setFieldsValue({
        fullName: result.fullName,
        profession: result.profession,
        experience: result.experience,
        photo: result.photo,
        websites: result?.websites?.links,
      });
    }
  }, [result]);

  if (isFetching) {
    return <Spinner />;
  }

  return (
    <div
      style={{
        padding: '1.25rem 1.25rem 3.75rem 2.5rem',
        maxWidth: '70%',
      }}
    >
      <Form
        name='form_items'
        onFinish={onFinish}
        form={form}
        autoComplete='off'
        layout='vertical'
        initialValues={{ websites: [''] }}
        onValuesChange={(changedValues): void => {
          if (changedValues !== undefined) {
            setIsChanged(true);
          }
        }}
      >
        <FormContent>
          <Form.Item
            name='fullName'
            label='Դասավանդողի անունը, ազգանունը'
            rules={[
              {
                required: true,
                message: 'Անուն դաշտը պարտադիր է',
              },
              { min: 3, message: 'Անուն դաշտը պետք է ունենա առնվազն 3 նիշ' },
              { max: 100, message: 'Անուն դաշտը պետք է ունենա առավելագույնը 100 նիշ' },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name='profession'
            label='Դասավանդողի մասնագիտությունը'
            rules={[
              {
                required: true,
                message: 'Մասնագիտությունը դաշտը պարտադիր է',
              },
              { min: 3, message: 'Մասնագիտությունը դաշտը պետք է ունենա առնվազն 3 նիշ' },
              { max: 100, message: 'Մասնագիտությունը դաշտը պետք է ունենա առավելագույնը 100 նիշ' },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name='experience'
            label='Դասավանդողի մասնագիտություն, աշխատանքային փորձը (կարճ բնութագիր)'
            rules={[
              {
                required: true,
                message: 'Աշխատանքային փորձը դաշտը պարտադիր է',
              },
              { min: 3, message: 'Աշխատանքային փորձը դաշտը պետք է ունենա առնվազն 3 նիշ' },
              { max: 500, message: 'Աշխատանքային փորձը դաշտը պետք է ունենա առավելագույնը 500 նիշ' },
            ]}
          >
            <TextArea />
          </Form.Item>
          <Form.List name='websites'>
            {(fields, { add, remove }): React.ReactNode => (
              <ListContainer>
                {fields.map(({ key, name, ...restField }) => (
                  <FormSpace key={key}>
                    <Form.Item
                      {...restField}
                      name={name}
                      label='Կցել դասավանդողի Սոցիալական կայքերը'
                      rules={[
                        {
                          pattern: new RegExp(/^(https?|ftp):\/\/[^\s/$.?#]+\.[^\s/$.?#]+[^\s]*$/i),
                          message:
                            'Սոցիալական կայքերի դաշտը պետք է ունենա հետեվյալ ձևաչափը http://example.com կամ https://example.com',
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    {fields.length > 1 ? <DeleteIcon onClick={(): void => remove(name)} /> : null}
                  </FormSpace>
                ))}
                {fields.length === 10 ? null : (
                  <AddButtonContainer>
                    <Form.Item>
                      <AsnButton
                        style={{
                          borderColor: `${GRAY}`,
                          color: `${DARK_GRAY}`,
                        }}
                        onClick={(): void => add()}
                      >
                        + Ավելացնել դաշտ
                      </AsnButton>
                    </Form.Item>
                  </AddButtonContainer>
                )}
              </ListContainer>
            )}
          </Form.List>
          <Form.Item
            name='photo'
            label='Կցել դասավանդողի լուսանկարը'
            rules={[
              {
                required: true,
                message: 'Դասավանդողի լուսանկարը պարտադիր է',
              },
            ]}
          >
            <Upload {...uploadPropsImage(setUrlPhoto)}>
              {!urlPhoto && !result?.photo ? (
                <AddPhotoSvg />
              ) : (
                <Image
                  preview={false}
                  src={urlPhoto ?? result.photo}
                  width={160}
                  height={160}
                  style={{ borderRadius: 6 }}
                />
              )}
            </Upload>
          </Form.Item>
          <ButtonsContainer>
            <AsnButton onClick={(): void => navigate(PATHS.COMPANY_LECTURERS)}>Չեղարկել</AsnButton>
            <AsnButton type='primary' htmlType='submit' disabled={disabledButton}>
              Հաստատել
            </AsnButton>
          </ButtonsContainer>
        </FormContent>
      </Form>
    </div>
  );
};

export default LecturersForm;
