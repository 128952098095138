import React, { useState } from 'react';
import { Row, Col, Button, Typography, message } from 'antd';
import { useParams, useNavigate } from 'react-router-dom';

import { AsnForm, AsnButton, Spinner, SuccessModal } from '../../../../components';
import { useGetStatementById, useGetStaticInfoByType, useUpdateStatement } from '../../../../api';
import { validate_messages, PATHS } from '../../../../constants';
import { ICreateTrainingVariables } from '../../../../types/company';
import { ReactComponent as EditSvg } from '../../../../assets/icons/edit.svg';
import OtherForm from '../OtherForm';

const { Title } = Typography;

const OtherEdit: React.FC = () => {
  const [openSuccessModal, setOpenSuccessModal] = useState<boolean>(false);
  const [urlPhoto, setUrlPhoto] = useState<string | undefined>(undefined);

  const { id } = useParams();
  const [form] = AsnForm.useForm();
  const navigate = useNavigate();

  const { mutate: updateStatement } = useUpdateStatement();
  const { data, isFetching } = useGetStaticInfoByType('OTHER', { enabled: true, staleTime: 1000 * 60 * 60 });
  const { data: statement, isFetching: isFetchingStatement } = useGetStatementById(id, {
    enabled: Boolean(id),
  });

  const onFinish = (): void => {
    const changedFields = form.getFieldsValue(true, (meta) => meta.touched === true) as ICreateTrainingVariables;

    if (Object.keys(changedFields).length || urlPhoto !== undefined) {
      updateStatement(
        { id: id, values: { ...changedFields, photo: urlPhoto ? urlPhoto : null } },
        {
          onSuccess: () => {
            setOpenSuccessModal(true);
          },
          onError: (error) => {
            void message.error(error as unknown as string, 2);
            setUrlPhoto(undefined);
          },
        },
      );
    } else {
      setOpenSuccessModal(true);
    }
  };

  const onClosedSuccessModal = (): void => {
    navigate(PATHS.COMPANY_ANNOUNCMENTS);
    setUrlPhoto(undefined);
    setOpenSuccessModal(false);
  };

  if (isFetching || isFetchingStatement) {
    return <Spinner />;
  }

  return (
    <>
      <Row style={{ paddingTop: 20 }}>
        <Col span={22} pull={1} push={1} xxl={{ span: 20, pull: 2, push: 2 }}>
          <Title level={5}>
            ԱՅԼ
            <EditSvg style={{ width: 16, height: 16 }} />
          </Title>
          <AsnForm layout='vertical' validateMessages={validate_messages} onFinish={(): void => onFinish()} form={form}>
            <OtherForm statement={statement} data={data} urlPhoto={urlPhoto} setUrlPhoto={setUrlPhoto} />
            <Row justify={'end'} style={{ padding: '24px 0px', gap: 16 }}>
              <AsnButton onClick={(): void => navigate(-1)}> Չեղարկել</AsnButton>
              <Button type='primary' htmlType='submit'>
                Հրապարակել
              </Button>
            </Row>
          </AsnForm>
        </Col>
      </Row>
      {openSuccessModal && (
        <SuccessModal
          openSuccessModal={openSuccessModal}
          onCloseModal={(): void => setOpenSuccessModal(false)}
          content='Ձեր հայտարարությունը հաջողությամբ խմբագրվել է'
        >
          <AsnButton style={{ marginTop: 20 }} onClick={onClosedSuccessModal}>
            Վերադառնալ իմ էջ
          </AsnButton>
        </SuccessModal>
      )}
    </>
  );
};

export default OtherEdit;
